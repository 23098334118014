import * as React from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Typography } from "@mui/material";
import BaseAppBar from "../../components/BaseAppBar";
import PageLayout from "../../components/PageLayout";

const Faq = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const hash = window.location.hash.slice(1); // Remove the '#' character from the hash
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        // @ts-ignore
        element.scrollIntoView({ behavior: "instant" });
      }
    }
  }, []);

  return (
    <PageLayout header={<BaseAppBar title="О сервисе" />}>
      <Box
        sx={{
          p: 4,
          background: "#ffffffba",
          boxSizing: "border-box",
        }}
      >
        <Typography id="about" variant="h5" fontWeight={600} mt={0} mb={3}>
          О приложении
        </Typography>
        <p style={{ textAlign: "justify" }}>
          <b>Приложение Ayuta</b> - специализированная социальная сеть, предназначенная для знакомств и общения. Для регистрации необходимо указать
          электронную почту, придумать надёжный пароль, указать имя/фамилию, пол, а также возраст и город проживания, затем перейти по ссылке и
          ознакомиться с политикой конфиденциальности перед началом использования платформы. После прохождения регистрации вы попадаете на главный
          экран, где появляется возможность добавлять или удалять свой аватар, а также размещать статус. На главном экране находятся вкладки: с
          друзьями; полученными подарками, с функцией просмотра дарителя при наведении. Есть возможность загружать посты, рассказывать об интересах,
          музыке, играх и всём том, что вас вдохновляет. В нашей социальной сети также доступна функция VIP статуса, которая даёт преимущество среди
          других пользователей и позволяет размещать ваш аккаунт в первых рядах поиска, а приятный бонус в виде знака короны будет ярко украшать
          аватар и указывать на обладателя VIP доступа. На главном экране размещено Колесо Фортуны - доступное для всех пользователей социальной сети.
          При активации Колеса Фортуны будут предложены платные подписки, различающиеся по времени активации: месяц, полгода, год. После покупки
          абонемента Колеса Фортуны, появится возможность испытать свою удачу, на это даётся две попытки. При первой попытке в качестве выигрыша
          выпадет подарок, а перед второй попыткой необходимо просмотреть короткий рекламный ролик, после чего у вас будет возможность выиграть ещё
          один подарок. Важно, каждый цвет Колеса Фортуны соответствует определённому цвету подарка в магазине подарков. Для удобства, в магазине
          подарков есть две вкладки, первая обозначает подарки выигранные вами, а вторая вкладка работает в качестве магазина, где можно приобрести
          желаемый подарок. Для общения в приложении Ayuta предусмотрен чат, в котором можно переписываться с собеседником и отправлять различные
          стикеры. Также в функционал входит информационная страница, благодаря которой вы сможете наблюдать кто посещал ваш профиль. В фильтре поиска
          есть возможность указать город, возраст и пол, для более точного результата. При нажатии на аватар вас перенаправит на желаемую страницу
          пользователя, в которой будут указаны контактные данные. Под главной фотографией профиля будет доступно функция добавления в друзья,
          создания диалога с собеседником, а также возможность подарить подарок. Боковое меню, которое присутствует на главной странице позволяет
          выбрать необходимый язык интерфейса, редактировать собственную анкету и сменить пароль. В том же боковом меню в подразделе Настройки
          возможно преобразить свой профиль и сделать его невидимым для других пользователей с помощью функции
          <b> Невидимка</b>, но при этом социальная сеть ограничивает доступ к некоторым действиям, как например: написать сообщение или же подарить
          подарок. В подразделе Настройки также существует функция, которая даёт возможность загрузить свой собственный фон для приложения. Последняя
          вкладка в подразделе Настройки позволяет управлять чёрным списком. В случае блокировки того или иного пользователя, возможно разблокировать
          его, зайдя во вкладку <b>Чёрный список</b>. Заблокировать пользователя можно зайдя в его профиль и нажав на три точки, расположенные в левом
          верхнем углу. Также для удобства в боковом меню добавлены вкладки: Мои подписки, Колесо Фортуны и при наличии VIP статус, где указано до
          какого времени активированы подписки. В боковом меню будут отображаться: сервисные сообщения, обновления, скидки и помимо этого информация о
          нарушении пользовательского соглашения, в случае которого Администрация приложения (сайта) Ayuta вправе заблокировать пользователя.
        </p>
        <Typography id="privacyPolicy" variant="h5" fontWeight={600} pt={4} pb={2}>
          Политика конфиденциальности
        </Typography>
        <p style={{ textAlign: "justify" }}>
          {/* Сайта (приложения) Ayuta.  */}
          Настоящая политика конфиденциальности персональной информации определяет порядок обработки персональных данных и меры по обеспечению
          безопасности персональных данных, предпринимаемые ООО "СН-АЮТА" (ОГРН-1173926016967). Документ составлен в соответствии с требованием
          Федерального закона от 27.07.2006 152-ФЗ "О персональных данных" Сайт (приложения) содержится в информационной системе, обеспечивающие
          доступность указанной информации в сети Интернет по адресу (доменному) ayuta.website , включая все уровни указанного домена как
          функционирующие на дату регистрации пользователя, при этом доступ к сайту возможен как посредством браузера в операционной системе
          персонального компьютера, так и посредством приложений для мобильных устройств и приложений для социальных сетей. 1. Регистрация
          пользователя, пароль и безопасность 1.1 Пользователям сайта может стать физическое лицо достигшее возраста 12+ лет. 1.2 Для того чтобы
          начать использование сайта (приложения) пользователь должен пройти процедуру регистрации на сайте. Для регистрации пользователь должен
          заполнить анкету, в процессе регистрации пользователь обязуется предоставить администрации достоверную полную и актуальную информацию о себе
          по вопросам предлагаемых в анкете. 1.3 В случае верного последовательного выполнения всех регистрационных действий на сайте создаётся
          персональная страница пользователя 1.4 Пользователь вправе зарегистрировать не более одной персональной страницы на сайте. 1.5 Адрес
          электронной почты и пароль являются необходимой и достаточной информации для доступа пользователя на сайт. Пользователь несёт
          ответственность за сохранность принадлежащих ему логина и пароля, любые действия совершённые на сайте с использованием логина и пароля
          пользователя, считаются совершёнными соответствующим пользователем. 1.6 Пользователь обязуется немедленно уведомить администрацию о любом
          случае неавторизованного неразрешённого пользователем доступа к сайту с логином и паролем пользователя и/или о любом нарушении сохранности
          логина и пароля. 2. Принципы обработки персональных данных 2.1 Обработка персональных данных пользователя осуществляется в соответствии с
          законодательством Российской Федерации администрация обрабатывает персональные данные пользователя в целях предоставления пользователю
          доступа к использованию функционала сайта. Администрация принимает все необходимые меры для защиты персональных данных пользователя от
          неправомерного доступа, изменения или уничтожение. 2.2 Администрация вправе использовать предоставленную пользователем информацию в том
          числе персональные данные, для обеспечение соблюдения требований действующего законодательства Российской Федерации в том числе в целях
          предупреждения и/или пресечения незаконных и/или противоправных действий пользователей 2.3 Администрация вправе использовать персональные
          данные, в том числе передавать такие данные партнёрам, в целях проведения маркетинговых, информационных и рекламных кампаний. 2.4
          Пользователь разрешает администрации использовать e-mail пользователя указанный при регистрации для направления рекламных сообщений,
          уведомление о событиях и новых возможностях сайта. 2.5 Пользователь осознаёт и соглашается с тем что изображение личной анкеты и
          персональные данные пользователя может сопровождать опубликованные пользователем материалы в рамках использования сайтом и будет доступной
          для неограниченного круга лиц. Пользователь соглашается с тем что администрация сайта вправе отображать изображение личной анкеты и имя
          пользователя в сервисе. 2.6 В случае удаление персональной страницы пользователя с сайта, администрация вправе осуществлять хранение
          информации, размещённой пользователям на сайте, а также в анкете, в течение необходимого времени по усмотрению администрации в целях
          надлежащего функционирования сайта и исполнение настоящего соглашения. 3. Права и обязанности пользователя 3.1 При использовании сайта
          пользователь обязан соблюдать положение действующего законодательства Российской Федерации и настоящего соглашения. Пользователь несёт
          личную ответственность за загруженный отправленый переданый или ставший доступным на сайте вследствие каких-либо других действий
          пользователя контент. 3.2 Администрация не несёт ответственности за контент, отправленный и/или размещённый на сайте пользователем. 3.3
          Пользователь обязуется: не публиковать, не распространять и не предоставлять доступ или иным образом использовать любую информацию, которая:
          а) содержит порнографию и/или тексты или любые сцены к сексуального характера в том числе с участием несовершеннолетних: б) содержит
          описание средств и способов суицида любое подстрекательство к его совершению. в) содепжит, пропагандирует и/или способствует разжиганию
          расовой, религиозной, национальной, этнической ненависти или вражды. г) пропагандирует насилие и жестокость, содержит экстремистские
          материалы. д) пропагандирует преступную деятельность или содержит советы инструкции или руководства по совершению преступных действий. е)
          пропагандирует или описывает привлекательность употребления наркотических веществ информацию о распространении наркотиков рецепты их
          изготовления и советы по употреблению. ж) носит мошеннически характер. з) нарушает неприкосновенность частной жизни других пользователей или
          третьих лиц. и) содержит клевету, грубые и оскорбительные выражения и предложения. к) нарушает другие права третьих лиц и действующие
          законодательство Российской Федерации. 3.4 не использовать сайт для размещения распространения контента содержащего вирусы или другие
          компьютерные коды файлы или программы предназначенные для нарушения уничтожения либо ограничение функциональности любого компьютерного или
          телекоммуникационного оборудования, для получения несанкционированного доступа к платным ресурсам в интернете а также размещение ссылок на
          вышеуказанную информацию. 3.5 Любые обращения, вопросы, предложения и претензии, связанные с функционированием сайта и/или действиями
          администрации, пользователь может направлять через форму обратной связи сайта по адресу, ayuta.website 3.6 Пользователь вправе в любое время
          удалить свою персональную страницу воспользовавшись функционалом сайта. 4. Права и обязанности администрации 4.1 Администрация может
          предоставлять пользователю по его запросу информацию касающуюся работы сайта и возможности сайта. 4.2 Администрация вправе приостановить
          либо прекратить действие регистрации пользователя на сайте и/или удалить любой контент загруженный на сайте пользователям без уведомления
          пользователя и или объяснение причин. 4.3 Администрация вправе менять оформление, содержания и функционал сайта по своему усмотрению. 4.4
          Администрация вправе размещать на сайте рекламу, в том числе на персональные страницы пользователя. 5. Информация, которую собирает
          Администрация сайта 5.1 Персональные данные. 5.2 Предоставляемые пользователям о себе самостоятельно при регистрации личной анкеты в
          интерфейсе сервиса, к персональным данным могут относиться в частности имя, фамилия, населённый пункт, дата рождения, пол, адрес электронной
          почты пользователя. 5.3 Данные предоставляемые при использовании сайта, в том числе информация об образовании, семейное положение, и иные
          данные доступные для редактирования в личной анкете. 5.4 Иные персональные данные доступ к которым пользователь предоставляет администрации
          сайта через веб-сайты или сервисы третьих лиц, в том числе при авторизации пользователя в сервисе. 5.5 По общему правилу сайта не проверять
          достоверность предоставляемые пользователями персональных данных и не имеет возможности оценить дееспособность пользователя в случаях
          предусмотренных соглашением Об использовании сайта , пользователь обязан предоставить подтверждение достоверности предоставляемых им своих
          персональных данных. 5.6 Администрация сайта не контролирует и не несёт ответственность за обработку информации с сайтами третьих лиц, на
          которые пользователь может перейти по ссылкам, доступным сервисе. 6. Безопасность персональных данных 6.1 Информация относящаяся к
          персональным данным ставшая известной в связи с реализацией трудовых отношений выполнения положений договора гражданско-правового характера
          стороной которого является субъект персональных данных и в связи с оказанием оператором услуг является конфиденциальной информацией и
          охраняется действующим законодательством РФ. 6.2 Лица, получившие доступ к обрабатываемым персональным данным подписали обязательства о
          неразглашении конфиденциальная информация о также предупреждены о возможных дисциплинарной административной гражданско-правовой и уголовной
          ответственности в случае нарушения норм и требований действующего законодательства Российской Федерации в области защиты персональных
          данных. 6.3 Лица, получивший доступ к обрабатываемым персональным данным не имеют права сообщать персональные данные субъектом персональных
          данных третьей стороне без письменного согласия такого субъекта за исключением случаев когда это необходимо в целях предупреждения угрозы
          жизни и здоровья субъекта персональных данных а также в случаях установленных законодательством РФ. 6.4 Лица получившие доступ к
          персональным данным, образуется не сообщать персональные данные в коммерческих целях, без письменного согласия субъекта персональных данных,
          обработка персональных данных субъектов персональных данных в целях продвижения товаров, работ, услуг, на рынке путём осуществления прямых
          контактов с потенциальным потребителем с помощью средств связи допускается только с его предварительного согласия. 7. Права пользователей.
          7.1 Пользователь вправе: а) осуществлять свободный бесплатный доступ к информации о с ебе по средствам загрузки своих персональных страниц в
          рамках предоставляемых сервиса с использованием логина и пароля. б) самостоятельно вносить изменения, дополнения в информацию о себе в
          личной анкете сервиса при условии что данные изменения актуализируют информацию. в) удалять информацию о себе, предоставленную в рамках
          личной анкеты сервиса, удаление личной анкеты, информации может повлечь невозможность использования некоторых функций сервиса. г) по запросу
          получать от администрации сайта информацию касающуюся обработки своих персональных данных. 8. Меры по защите информации. 8.1 Администрация
          сайта принимает все необходимые и достаточные правовые, организационные и технические меры для защиты персональных данных пользователей и
          третьих лиц от неправомерного или случайного доступа к ним, уничтожение, изменения, блокирования распространения а также от иных
          неправомерных действий с ними. К таким мерам, относятся, в частности внутренняя проверка процессов сбора, хранения и обработки персональных
          данных и мер безопасности, включая соответствующее шифрование и меры по обеспечению физической безопасности персональных данных для
          предотвращения неавторизованного доступа к системам, в которых хранятся персональные данные. 9. Заключительные положения. 9.1 Все возможные
          споры по поводу соглашения между пользователем и администрацией сайта будут регулироваться в соответствии с действующим законодательством
          Российской Федерации. Претензионный досудебный порядок урегулирования споров является обязательным, срок ответа на претензию 10 (
          десять)рабочих дней с даты её получения, в случае невозможности урегулировать спор путём переговоров, спор подлежит рассмотрению в суде по
          месту нахождения администрации. 9.2 П остальных положений соглашения во всём что не ризнание судом какого-либо положения соглашения
          недействительным не влечёт недействительности остальных положений соглашения. Во всём что не урегулировано условиями соглашения стороны
          руководствуются действующим законодательством Российской Федерации. Настоящее соглашение вступает в силу для пользователя с момента его
          регистрации на сайте и действует в течение неопределённого срока. 9.3 Все вопросы касающиеся настоящей политики просим направлять в службу
          поддержки через интерфейс сервиса либо по адресу 236029 , г. Калининград, ул. Зеленая д.91 кв, 29, ООО "СН-АЮТА" 9.4 В случае необходимости
          направления отзыва согласия на обработку персональных данных, выявление факта неправомерной обработки персональных данных или при наличии
          иных оснований, субъект персональных данных, чьи данные незаконно используются, обязан Направить в адрес оператора письменное заявление об
          удалении таких персональных данных. Действующая редакция настоящей политики размещена на сайте сервиса и доступна в сети Интернет по адресу.
          ayuta website
        </p>
        <Typography id="termsOfUse" variant="h5" fontWeight={600} pt={4} pb={2}>
          Пользовательское соглашение
        </Typography>
        <p style={{ textAlign: "justify" }}>
          Перед использованием сайта, приложения (далее - "Ayuta") пожалуйста ознакомьтесь с условиями нижеследующего пользовательского соглашения
          (далее - Соглашение). Настоящее соглашение регулирует отношения между пользователем сайта приложения ( далее - Пользователь) и ООО "СН-АЮТА"
          (ОГРН-1173926016967). Возникающие при использовании программного обеспечения ("Ayuta"), для смартфонов (мобильных) и иных других устройств
          (далее - устройства), предназначенного для технического доступа к сайту приложению ("Ayuta").. Любое использование ( каждое скачивание,
          установка, запуск, работа с сайтом приложением, использование каких-либо его отдельных функциональных возможностей, служб, услуг и т.п.).
          Пользователем мобильного приложения, сайтом ("Ayuta") означает полное и безоговорочное принятие пользователем условий настоящего соглашения.
          1. Общие условия. 1.1 Использования сервисов сайта, приложения, регулируется нормами действующего законодательства Российской Федерации. 1.2
          Для получения доступа к сервисам сайта приложения необходимо выполнить следующие действия: - заполнить регистрационную форму - ознакомиться
          с настоящим пользовательским соглашением и обязаться выполнять его условия. 1.3 регистрация пользователя и получения доступа в приложение
          сайт, означает ознакомление и согласие пользователя с настоящим пользовательским соглашением и политикой обработки персональных данных.
          Пользователь зарегистрированный на сайте или приложении, автоматически считается ознакомлены с настоящим пользовательским соглашением. 1.4
          Пользователь получивший доступ к сайту приложению обязуется соблюдать условия пользовательского соглашения политики конфиденциальности и
          законодательство Российской Федерации. 1.5 Пользователь может использовать сайт приложения исключительно для личного пользования. 2.
          Определение понятий. 2.1 Сайт приложение ("Ayuta") - что-то среднее между социальной сетью и мессенджером, представляющая собой совокупность
          информации. 2.2 Пользовательское соглашение- настоящее соглашение, размещенная на странице сайта приложения в сети Интернет по сетевому
          адресу: https://ayuta.website/termsofuse.html, а также иные правила и документы регламентирующие работу сайта и приложения определяющее
          порядок использования материалов и сервисов размещенных на иных страницах данного сайта приложения. 2.3 Пользователь сайта приложения-
          физическое лицо которое выполнило (произвело) процедуру регистрации на сайте или приложении, в качестве пользователя в соответствии с
          условиями пользовательского соглашения. 2.4 Посетитель сайта приложения - любое лицо осуществляющие (-вившее) доступ к сайту приложению и
          размещённой на нём информации посредством использования сети интернет. 2.5 Сайт приложение - совокупность программ для
          электронно-вычислительных машин и иная информация в текстовом графическом звуковом или другом формате содержащиеся в информационной системе,
          доступ к которой обеспечивается посредством информационно-телекоммуникационной сети "Интернет" (далее - "Интернет"), по доменному имени
          https://ayuta.website/ 2.6 Сервисы и или сервисы сайта приложения - функциональные возможности, службы, услуги и инструменты, доступные для
          посетителей и пользователей сайта приложения. 2.7 Страница сайта приложения - часть сайта приложения, доступ к которой осуществляется по
          указателю, состоящему из доменного имени и символов, определенных владельцем сайта приложения. 3. Авторское право. 3.1 Пользователь и
          Посетитель сайта приложения ознакомлены с тем что автором произведения науки, литературы или искусства признаётся гражданин, творческим
          трудом которого оно создано (статья 1257 Гк РФ), а владелец сайта приложения является автором, размещённой на сайте и в приложении
          информации. 3.2 Все исключительные права на сайт и приложение, как на комплексный объект интеллектуальной собственности, а также
          исключительные права на любые объекты интеллектуальной собственности, доступные при использовании сайта приложения, в том числе, имя Ayuta,
          элементы дизайна, текст, изображения, видео, аудио, фонограммы, программы для электронно-вычислительных машин, базы данных, и иные объекты
          интеллектуальной собственности полностью или в части, принадлежат владельцу сайта приложения. 3.3 Запрещается какое-либо использование
          объектов интеллектуальной собственности, размещённых на сайте или приложение без получения на это предварительного согласия владельца сайта
          приложения. 3.4 Приобретая доступ на сайте и в приложении https://ayuta.website/ пользователь не получает права в дальнейшем распространять
          материалы и передавать его третьим лицам как бесплатно так и за вознаграждение. 3.5 За нарушение авторского права владельца сайта приложения
          предусмотрена гражданская (статья 1301 Гражданского кодекса Российской Федерации), административная (статья 7.12 " нарушение авторских и
          смежных прав, изобретательских и патентных прав" Кодекса Российской Федерации об административных правонарушениях) и уголовная (статья 146
          "нарушение авторских и смежных прав" Уголовного кодекса Российской Федерации) ответственность. 4. Обязанности пользователя. 4.1 При
          регистрации на сайте или приложении пользователь обязан предоставить администрации сайта необходимую достоверную и актуальную информацию для
          формирования персональной страницы пользователя, включая уникальные для каждого пользователя логин и пароль, для доступа к сервисам сайта
          приложения, а также фамилию и имя, возраст, пол, страна, населённый пункт. 4.2 Пользователь несёт ответственность за достоверность,
          актуальность, полноту и соответствие законодательству Российской Федерации предоставленной при регистрации информации и её чистоту от
          претензий третьих лиц. 4.3 Пользователь обязуется не предпринимать действий которые могут рассматриваться как нарушение Российского
          Законодательства или нормы Международного права, в том числе в сфере интеллектуальной собственности, авторских и или смежных прав а также
          любых действий которые приводят или могут привести к нарушению нормальной работы сайта приложения. 4.4 Использование материалов сайта
          приложения без согласия правообладателя (владельца сайта приложения) не допускается (статья 1270 Гк РФ). Для правомерного использования
          сайта приложение необходимо соблюдать правила настоящего пользовательского соглашения. 4.4 Пользователь не вправе: 4.4.1 Использовать сайт
          приложение его материалы и сервисы для иных целей, чем определено в настоящем пользовательском соглашении. 4.4.2 Выполнять действия
          нарушающие нормальное функционирование сайта приложения причиняющие вред другим пользователем и или посетителям. 4.4.3 Выдавать себя за
          другое лицо или иным образом обманывать (вводить в заблуждение) владельца сайта приложения, использовать информацию, которая является
          ложной, неверной, неточной и (или) вводящей в заблуждение. 4.4.4 Извлекать, собирать, систематизировать, хранить и использовать при помощи
          технических и программных средств или иным образом информацию, имеющиеся на сайте или в приложении, несанкционированную запись и извлечения
          материалов из системы сайта и приложения. 4.4.5 Использовать сайт приложение и размещённую на нём информацию для введения коммерческой
          (предпринимательской) деятельности. 4,4,6 Содействовать каким-либо образом действием третьих лиц, направленным на нарушение ограничений и
          запретов, установленных пользовательским соглашением. 4.4.7 Нарушать иные обязанности, установленные пользовательским соглашением и (или)
          законодательством Российской Федерации. 5. Применение пользовательского соглашения. 5.1 Настоящее пользовательское соглашение регулирует
          отношения между владельцем сайта приложения и посетителям и (или) пользователем, возникающие при использовании сайта приложения. 5.2
          Владелец сайта приложения предлагает посетителю и (или) пользователю на условиях настоящего пользовательского соглашения осуществить
          пользование сайтом приложением его материалами и осуществлять пользование иными предлагаемыми материалами и сервисам. 5.3 До момента начала
          использования сайта приложения, посетитель и или (пользователь) обязан внимательно ознакомиться с настоящим пользовательским соглашением.
          Начав использовать сайт или приложение посредством использования сети Интернет посетитель и (или) пользователь считается принявшим условия
          настоящего пользовательского соглашения в полном объёме без каких-либо оговорок, ограничений и исключений. 5.4 В случае несогласия
          посетителя и (или) пользователя с любыми из положений пользовательского соглашения посетитель и (или) пользователь не вправе (не должен)
          приступать к использованию сайта приложения, и обязан прекратить доступ к сайту приложению и (или) его использование в случае, если такое
          было начато. 5.5 Владелец сайта приложения вправе в любое время в одностороннем порядке без предварительного уведомления кого бы то ни было
          внести изменения в пользовательское соглашение путем размещения изменённой версии пользовательского соглашения с внесенными в него по своему
          усмотрению изменениями и дополнениями на странице сайта а также по сетевому адресу: https://ayuta.website/ 5.6 Новая редакция
          пользовательского соглашения вступает в силу немедленно после её размещения на сайте и приложении в случае если владельцем сайта приложения
          были внесены какие-либо изменения и (или) дополнения в пользовательское соглашение с которыми посетители и (или) пользователь окажется не
          согласен посетитель и (или) пользователь обязаны незамедлительно прекратить использование сайта и приложения. 6. Регистрация на сайте
          (приложении) 6.1 Посетитель в праве посредством использования сети Интернет получать без каких-либо ограничений доступ к сайту приложению и
          размещённой на нём информации находящейся в открытом доступе, в том числе к объявлениям и другим предложением услуг. 6.2 Пользователь обязан
          немедленно уведомить владельца сайта приложения о любом случае несанкционированного (не разрешённого) пользователем доступа к страницы
          пользователя, с использованием ссылки пользователя и или о любом ином нарушении (подозрениях о нарушении) конфиденциальности своей страницы.
          Уведомление происходит посредством личного обращения в сообщениях, в администрацию сайта приложения. 7. Прочие положения. 7.1 Все возможные
          споры и разногласия, вытекающие из отношений регулируемых настоящим пользовательским соглашением, разрешаются в порядке, установленном
          законодательством Российской Федерации. 7.2 Признание недействительными и (или) не имеющими юридической силы отдельных положений настоящего
          пользовательского соглашения не влечёт за собой признание недействительными иных его положений и (или) всего пользовательского соглашения в
          целом. 7.3 Настоящее пользовательское соглашение составлено на русском языке размещено на сайте (приложении) и обязательно к соблюдению
          пользователями и посетителями сайта (приложения) Пользователи обязаны соблюдать действующие Российское и международное законодательство а
          также размещать только такую информацию которая соответствует настоящим правилам пользовательского соглашения. Пользователь подтверждает,
          что ознакомлен со всеми положениями настоящего пользовательского соглашения, безусловно принимает их и обязуется соблюдать.
        </p>

        <Button variant="contained" size="large" onClick={() => navigate("/feedback")} sx={{ borderRadius: 2 }}>
          Написать разработчикам
        </Button>
      </Box>
    </PageLayout>
  );
};

export default Faq;
