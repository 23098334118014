export type CityOption = {
  id?: number;
  region: string;
  label: string;
};

const citiesList: CityOption[] = [
  {
    region: 'Москва и Московская обл.',
    label: 'Москва',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Абрамцево',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Алабино',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Апрелевка',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Архангельское',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Ашитково',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Байконур',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Бакшеево',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Балашиха',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Барыбино',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Белоомут',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Белые Столбы',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Бородино',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Бронницы',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Быково',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Валуево',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Вербилки',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Верея',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Видное',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Внуково',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Вождь Пролетариата',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Волоколамск',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Вороново',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Воскресенск',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Восточный',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Востряково',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Высоковск',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Голицино',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Деденево',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Дедовск',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Джержинский',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Дмитров',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Долгопрудный',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Домодедово',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Дорохово',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Дрезна',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Дубки',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Дубна',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Егорьевск',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Железнодорожный',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Жилево',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Жуковский',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Загорск',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Загорянский',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Запрудная',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Зарайск',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Звенигород',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Зеленоград',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Ивантеевка',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Икша',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Ильинский',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Истра',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Калининград',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Кашира',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Керва',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Климовск',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Клин',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Клязьма',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Кожино',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Кокошкино',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Коломна',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Колюбакино',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Королев',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Косино',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Котельники',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Красково',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Красноармейск',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Красногорск',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Краснозаводск',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Краснознаменск',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Красный Ткач',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Крюково',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Кубинка',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Купавна',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Куровское',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Лесной Городок',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Ликино-Дулево',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Лобня',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Лопатинский',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Лосино-Петровский',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Лотошино',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Лукино',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Луховицы',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Лыткарино',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Львовский',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Люберцы',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Малаховка',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Михайловское',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Михнево',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Можайск',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Монино',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Муханово',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Мытищи',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Нарофоминск',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Нахабино',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Некрасовка',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Немчиновка',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Новобратцевский',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Новоподрезково',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Ногинск',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Обухово',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Одинцово',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Ожерелье',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Озеры',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Октябрьский',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Опалиха',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Орехово-Зуево',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Павловский Посад',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Первомайский',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Пески',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Пироговский',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Подольск',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Полушкино',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Правдинский',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Привокзальный',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Пролетарский',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Протвино',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Пушкино',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Пущино',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Радовицкий',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Раменское',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Реутов',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Решетниково',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Родники',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Рошаль',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Рублево',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Руза',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Салтыковка',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Северный',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Сергиев Посад',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Серебряные Пруды',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Серпухов',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Солнечногорск',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Солнцево',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Софрино',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Старая Купавна',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Старбеево',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Ступино',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Сходня',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Талдом',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Текстильщик',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Темпы',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Тишково',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Томилино',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Троицк',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Туголесский Бор',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Тучково',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Уваровка',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Удельная',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Успенское',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Фирсановка',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Фосфоритный',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Фрязино',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Фряново',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Химки',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Хорлово',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Хотьково',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Черкизово',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Черноголовка',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Черусти',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Чехов',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Шарапово',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Шатура',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Шатурторф',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Шаховская',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Шереметьевский',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Щелково',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Щербинка',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Электрогорск',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Электросталь',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Электроугли',
  },
  {
    region: 'Москва и Московская обл.',
    label: 'Яхрома',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Санкт-Петербург',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Александровская',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Бокситогорск',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Большая Ижора',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Будогощь',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Вознесенье',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Волосово',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Волхов',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Всеволожск',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Выборг',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Вырица',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Высоцк',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Гатчина',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Дружная Горка',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Дубровка',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Ефимовский',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Зеленогорск',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Ивангород',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Каменногорск',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Кикерино',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Кингисепп',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Кириши',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Кировск',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Кобринское',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Колпино',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Коммунар',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Кронштадт',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Лисий Нос',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Лодейное Поле',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Ломоносов',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Луга',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Павловск',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Парголово',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Петродворец',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Пикалёво',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Подпорожье',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Приозерск',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Пушкин',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Сестрорецк',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Сланцы',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Сосновый Бор',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Тихвин',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Тосно',
  },
  {
    region: 'Санкт-Петербург и область',
    label: 'Шлиссельбург',
  },
  {
    region: 'Адыгея',
    label: 'Адыгейск',
  },
  {
    region: 'Адыгея',
    label: 'Майкоп',
  },
  {
    region: 'Алтайский край',
    label: 'Акташ',
  },
  {
    region: 'Алтайский край',
    label: 'Акутиха',
  },
  {
    region: 'Алтайский край',
    label: 'Алейск',
  },
  {
    region: 'Алтайский край',
    label: 'Алтайский',
  },
  {
    region: 'Алтайский край',
    label: 'Баево',
  },
  {
    region: 'Алтайский край',
    label: 'Барнаул',
  },
  {
    region: 'Алтайский край',
    label: 'Белово',
  },
  {
    region: 'Алтайский край',
    label: 'Белокуриха',
  },
  {
    region: 'Алтайский край',
    label: 'Белоярск',
  },
  {
    region: 'Алтайский край',
    label: 'Бийск',
  },
  {
    region: 'Алтайский край',
    label: 'Благовещенск',
  },
  {
    region: 'Алтайский край',
    label: 'Боровлянка',
  },
  {
    region: 'Алтайский край',
    label: 'Бурла',
  },
  {
    region: 'Алтайский край',
    label: 'Бурсоль',
  },
  {
    region: 'Алтайский край',
    label: 'Волчиха',
  },
  {
    region: 'Алтайский край',
    label: 'Горно-Алтайск',
  },
  {
    region: 'Алтайский край',
    label: 'Горняк',
  },
  {
    region: 'Алтайский край',
    label: 'Ельцовка',
  },
  {
    region: 'Алтайский край',
    label: 'Залесово',
  },
  {
    region: 'Алтайский край',
    label: 'Заринск',
  },
  {
    region: 'Алтайский край',
    label: 'Заток',
  },
  {
    region: 'Алтайский край',
    label: 'Змеиногорск',
  },
  {
    region: 'Алтайский край',
    label: 'Камень-на-Оби',
  },
  {
    region: 'Алтайский край',
    label: 'Ключи',
  },
  {
    region: 'Алтайский край',
    label: 'Кош-Агач',
  },
  {
    region: 'Алтайский край',
    label: 'Красногорское',
  },
  {
    region: 'Алтайский край',
    label: 'Краснощеково',
  },
  {
    region: 'Алтайский край',
    label: 'Кулунда',
  },
  {
    region: 'Алтайский край',
    label: 'Кытманово',
  },
  {
    region: 'Алтайский край',
    label: 'Мамонтово',
  },
  {
    region: 'Алтайский край',
    label: 'Новичиха',
  },
  {
    region: 'Алтайский край',
    label: 'Новоалтайск',
  },
  {
    region: 'Алтайский край',
    label: 'Онгудай',
  },
  {
    region: 'Алтайский край',
    label: 'Павловск',
  },
  {
    region: 'Алтайский край',
    label: 'Петропавловское',
  },
  {
    region: 'Алтайский край',
    label: 'Поспелиха',
  },
  {
    region: 'Алтайский край',
    label: 'Ребриха',
  },
  {
    region: 'Алтайский край',
    label: 'Родино',
  },
  {
    region: 'Алтайский край',
    label: 'Рубцовск',
  },
  {
    region: 'Алтайский край',
    label: 'Славгород',
  },
  {
    region: 'Алтайский край',
    label: 'Смоленское',
  },
  {
    region: 'Алтайский край',
    label: 'Солонешное',
  },
  {
    region: 'Алтайский край',
    label: 'Солтон',
  },
  {
    region: 'Алтайский край',
    label: 'Староаллейское',
  },
  {
    region: 'Алтайский край',
    label: 'Табуны',
  },
  {
    region: 'Алтайский край',
    label: 'Тальменка',
  },
  {
    region: 'Алтайский край',
    label: 'Топчиха',
  },
  {
    region: 'Алтайский край',
    label: 'Троицкое',
  },
  {
    region: 'Алтайский край',
    label: 'Турочак',
  },
  {
    region: 'Алтайский край',
    label: 'Тюменцево',
  },
  {
    region: 'Алтайский край',
    label: 'Угловское',
  },
  {
    region: 'Алтайский край',
    label: 'Усть-Калманка',
  },
  {
    region: 'Алтайский край',
    label: 'Усть-Кан',
  },
  {
    region: 'Алтайский край',
    label: 'Усть-Кокса',
  },
  {
    region: 'Алтайский край',
    label: 'Усть-Улаган',
  },
  {
    region: 'Алтайский край',
    label: 'Усть-Чарышская Пристань',
  },
  {
    region: 'Алтайский край',
    label: 'Хабары',
  },
  {
    region: 'Алтайский край',
    label: 'Целинное',
  },
  {
    region: 'Алтайский край',
    label: 'Чарышское',
  },
  {
    region: 'Алтайский край',
    label: 'Шебалино',
  },
  {
    region: 'Алтайский край',
    label: 'Шелаболиха',
  },
  {
    region: 'Алтайский край',
    label: 'Шипуново',
  },
  {
    region: 'Амурская обл.',
    label: 'Айгунь',
  },
  {
    region: 'Амурская обл.',
    label: 'Архара',
  },
  {
    region: 'Амурская обл.',
    label: 'Белогорск',
  },
  {
    region: 'Амурская обл.',
    label: 'Благовещенск (Амурская обл.)',
  },
  {
    region: 'Амурская обл.',
    label: 'Бурея',
  },
  {
    region: 'Амурская обл.',
    label: 'Возжаевка',
  },
  {
    region: 'Амурская обл.',
    label: 'Екатеринославка',
  },
  {
    region: 'Амурская обл.',
    label: 'Ерофей Павлович',
  },
  {
    region: 'Амурская обл.',
    label: 'Завитинск',
  },
  {
    region: 'Амурская обл.',
    label: 'Зея',
  },
  {
    region: 'Амурская обл.',
    label: 'Златоустовск',
  },
  {
    region: 'Амурская обл.',
    label: 'Ивановка',
  },
  {
    region: 'Амурская обл.',
    label: 'Коболдо',
  },
  {
    region: 'Амурская обл.',
    label: 'Магдагачи',
  },
  {
    region: 'Амурская обл.',
    label: 'Новобурейский',
  },
  {
    region: 'Амурская обл.',
    label: 'Поярково',
  },
  {
    region: 'Амурская обл.',
    label: 'Райчихинск',
  },
  {
    region: 'Амурская обл.',
    label: 'Ромны',
  },
  {
    region: 'Амурская обл.',
    label: 'Свободный',
  },
  {
    region: 'Амурская обл.',
    label: 'Серышево',
  },
  {
    region: 'Амурская обл.',
    label: 'Сковородино',
  },
  {
    region: 'Амурская обл.',
    label: 'Стойба',
  },
  {
    region: 'Амурская обл.',
    label: 'Тамбовка',
  },
  {
    region: 'Амурская обл.',
    label: 'Тында',
  },
  {
    region: 'Амурская обл.',
    label: 'Шимановск',
  },
  {
    region: 'Амурская обл.',
    label: 'Экимчан',
  },
  {
    region: 'Амурская обл.',
    label: 'Ядрино',
  },
  {
    region: 'Архангельская обл.',
    label: 'Амдерма',
  },
  {
    region: 'Архангельская обл.',
    label: 'Архангельск',
  },
  {
    region: 'Архангельская обл.',
    label: 'Березник',
  },
  {
    region: 'Архангельская обл.',
    label: 'Вельск',
  },
  {
    region: 'Архангельская обл.',
    label: 'Верхняя Тойма',
  },
  {
    region: 'Архангельская обл.',
    label: 'Волошка',
  },
  {
    region: 'Архангельская обл.',
    label: 'Вычегодский',
  },
  {
    region: 'Архангельская обл.',
    label: 'Емца',
  },
  {
    region: 'Архангельская обл.',
    label: 'Илеза',
  },
  {
    region: 'Архангельская обл.',
    label: 'Ильинско-Подомское',
  },
  {
    region: 'Архангельская обл.',
    label: 'Каргополь',
  },
  {
    region: 'Архангельская обл.',
    label: 'Карпогоры',
  },
  {
    region: 'Архангельская обл.',
    label: 'Кодино',
  },
  {
    region: 'Архангельская обл.',
    label: 'Коноша',
  },
  {
    region: 'Архангельская обл.',
    label: 'Коряжма',
  },
  {
    region: 'Архангельская обл.',
    label: 'Котлас',
  },
  {
    region: 'Архангельская обл.',
    label: 'Красноборск',
  },
  {
    region: 'Архангельская обл.',
    label: 'Лешуконское',
  },
  {
    region: 'Архангельская обл.',
    label: 'Мезень',
  },
  {
    region: 'Архангельская обл.',
    label: 'Мирный',
  },
  {
    region: 'Архангельская обл.',
    label: 'Нарьян-Мар',
  },
  {
    region: 'Архангельская обл.',
    label: 'Новодвинск',
  },
  {
    region: 'Архангельская обл.',
    label: 'Няндома',
  },
  {
    region: 'Архангельская обл.',
    label: 'Онега',
  },
  {
    region: 'Архангельская обл.',
    label: 'Пинега',
  },
  {
    region: 'Архангельская обл.',
    label: 'Плесецк',
  },
  {
    region: 'Архангельская обл.',
    label: 'Северодвинск',
  },
  {
    region: 'Архангельская обл.',
    label: 'Сольвычегодск',
  },
  {
    region: 'Архангельская обл.',
    label: 'Холмогоры',
  },
  {
    region: 'Архангельская обл.',
    label: 'Шенкурск',
  },
  {
    region: 'Архангельская обл.',
    label: 'Яренск',
  },
  {
    region: 'Астраханская обл.',
    label: 'Астрахань',
  },
  {
    region: 'Астраханская обл.',
    label: 'Ахтубинск',
  },
  {
    region: 'Астраханская обл.',
    label: 'Верхний Баскунчак',
  },
  {
    region: 'Астраханская обл.',
    label: 'Володарский',
  },
  {
    region: 'Астраханская обл.',
    label: 'Енотаевка',
  },
  {
    region: 'Астраханская обл.',
    label: 'Икряное',
  },
  {
    region: 'Астраханская обл.',
    label: 'Камызяк',
  },
  {
    region: 'Астраханская обл.',
    label: 'Капустин Яр',
  },
  {
    region: 'Астраханская обл.',
    label: 'Красный Яр',
  },
  {
    region: 'Астраханская обл.',
    label: 'Лиман',
  },
  {
    region: 'Астраханская обл.',
    label: 'Началово',
  },
  {
    region: 'Астраханская обл.',
    label: 'Харабали',
  },
  {
    region: 'Астраханская обл.',
    label: 'Черный Яр',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Аксаково',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Амзя',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Аскино',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Баймак',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Бакалы',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Белебей',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Белорецк',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Бижбуляк',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Бирск',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Благовещенск',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Большеустьикинское',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Бураево',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Верхнеяркеево',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Верхние Киги',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Верхние Татышлы',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Верхний Авзян',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Давлеканово',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Дуван',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Дюртюли',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Ермекеево',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Ермолаево',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Зилаир',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Зирган',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Иглино',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Инзер',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Исянгулово',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Ишимбай',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Кананикольское',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Кандры',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Караидель',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Караидельский',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Киргиз-Мияки',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Красноусольский',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Кумертау',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Кушнаренково',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Малояз',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Мелеуз',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Месягутово',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Мраково',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Нефтекамск',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Октябрьский',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Раевский',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Салават',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Сибай',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Старобалтачево',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Старосубхангулово',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Стерлибашево',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Стерлитамак',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Туймазы',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Уфа',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Учалы',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Федоровка',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Чекмагуш',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Чишмы',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Шаран',
  },
  {
    region: 'Башкортостан(Башкирия)',
    label: 'Янаул',
  },
  {
    region: 'Белгородская обл.',
    label: 'Алексеевка',
  },
  {
    region: 'Белгородская обл.',
    label: 'Белгород',
  },
  {
    region: 'Белгородская обл.',
    label: 'Борисовка',
  },
  {
    region: 'Белгородская обл.',
    label: 'Валуйки',
  },
  {
    region: 'Белгородская обл.',
    label: 'Вейделевка',
  },
  {
    region: 'Белгородская обл.',
    label: 'Волоконовка',
  },
  {
    region: 'Белгородская обл.',
    label: 'Грайворон',
  },
  {
    region: 'Белгородская обл.',
    label: 'Губкин',
  },
  {
    region: 'Белгородская обл.',
    label: 'Ивня',
  },
  {
    region: 'Белгородская обл.',
    label: 'Короча',
  },
  {
    region: 'Белгородская обл.',
    label: 'Красногвардейское',
  },
  {
    region: 'Белгородская обл.',
    label: 'Новый Оскол',
  },
  {
    region: 'Белгородская обл.',
    label: 'Ракитное',
  },
  {
    region: 'Белгородская обл.',
    label: 'Ровеньки',
  },
  {
    region: 'Белгородская обл.',
    label: 'Старый Оскол',
  },
  {
    region: 'Белгородская обл.',
    label: 'Строитель',
  },
  {
    region: 'Белгородская обл.',
    label: 'Чернянка',
  },
  {
    region: 'Белгородская обл.',
    label: 'Шебекино',
  },
  {
    region: 'Брянская обл.',
    label: 'Алтухово',
  },
  {
    region: 'Брянская обл.',
    label: 'Белая Березка',
  },
  {
    region: 'Брянская обл.',
    label: 'Белые Берега',
  },
  {
    region: 'Брянская обл.',
    label: 'Большое Полпино',
  },
  {
    region: 'Брянская обл.',
    label: 'Брянск',
  },
  {
    region: 'Брянская обл.',
    label: 'Бытошь',
  },
  {
    region: 'Брянская обл.',
    label: 'Выгоничи',
  },
  {
    region: 'Брянская обл.',
    label: 'Вышков',
  },
  {
    region: 'Брянская обл.',
    label: 'Гордеевка',
  },
  {
    region: 'Брянская обл.',
    label: 'Дубровка',
  },
  {
    region: 'Брянская обл.',
    label: 'Дятьково',
  },
  {
    region: 'Брянская обл.',
    label: 'Жирятино',
  },
  {
    region: 'Брянская обл.',
    label: 'Жуковка',
  },
  {
    region: 'Брянская обл.',
    label: 'Злынка',
  },
  {
    region: 'Брянская обл.',
    label: 'Ивот',
  },
  {
    region: 'Брянская обл.',
    label: 'Карачев',
  },
  {
    region: 'Брянская обл.',
    label: 'Клетня',
  },
  {
    region: 'Брянская обл.',
    label: 'Климово',
  },
  {
    region: 'Брянская обл.',
    label: 'Клинцы',
  },
  {
    region: 'Брянская обл.',
    label: 'Кокаревка',
  },
  {
    region: 'Брянская обл.',
    label: 'Комаричи',
  },
  {
    region: 'Брянская обл.',
    label: 'Красная Гора',
  },
  {
    region: 'Брянская обл.',
    label: 'Локоть',
  },
  {
    region: 'Брянская обл.',
    label: 'Мглин',
  },
  {
    region: 'Брянская обл.',
    label: 'Навля',
  },
  {
    region: 'Брянская обл.',
    label: 'Новозыбков',
  },
  {
    region: 'Брянская обл.',
    label: 'Погар',
  },
  {
    region: 'Брянская обл.',
    label: 'Почеп',
  },
  {
    region: 'Брянская обл.',
    label: 'Ржаница',
  },
  {
    region: 'Брянская обл.',
    label: 'Рогнедино',
  },
  {
    region: 'Брянская обл.',
    label: 'Севск',
  },
  {
    region: 'Брянская обл.',
    label: 'Стародуб',
  },
  {
    region: 'Брянская обл.',
    label: 'Суземка',
  },
  {
    region: 'Брянская обл.',
    label: 'Сураж',
  },
  {
    region: 'Брянская обл.',
    label: 'Трубчевск',
  },
  {
    region: 'Брянская обл.',
    label: 'Унеча',
  },
  {
    region: 'Бурятия',
    label: 'Бабушкин',
  },
  {
    region: 'Бурятия',
    label: 'Багдарин',
  },
  {
    region: 'Бурятия',
    label: 'Баргузин',
  },
  {
    region: 'Бурятия',
    label: 'Баянгол',
  },
  {
    region: 'Бурятия',
    label: 'Бичура',
  },
  {
    region: 'Бурятия',
    label: 'Выдрино',
  },
  {
    region: 'Бурятия',
    label: 'Гусиное Озеро',
  },
  {
    region: 'Бурятия',
    label: 'Гусиноозерск',
  },
  {
    region: 'Бурятия',
    label: 'Заиграево',
  },
  {
    region: 'Бурятия',
    label: 'Закаменск',
  },
  {
    region: 'Бурятия',
    label: 'Иволгинск',
  },
  {
    region: 'Бурятия',
    label: 'Илька',
  },
  {
    region: 'Бурятия',
    label: 'Кабанск',
  },
  {
    region: 'Бурятия',
    label: 'Каменск',
  },
  {
    region: 'Бурятия',
    label: 'Кижинга',
  },
  {
    region: 'Бурятия',
    label: 'Курумкан',
  },
  {
    region: 'Бурятия',
    label: 'Кырен',
  },
  {
    region: 'Бурятия',
    label: 'Кяхта',
  },
  {
    region: 'Бурятия',
    label: 'Монды',
  },
  {
    region: 'Бурятия',
    label: 'Мухоршибирь',
  },
  {
    region: 'Бурятия',
    label: 'Нижнеангарск',
  },
  {
    region: 'Бурятия',
    label: 'Орлик',
  },
  {
    region: 'Бурятия',
    label: 'Петропавловка',
  },
  {
    region: 'Бурятия',
    label: 'Романовка',
  },
  {
    region: 'Бурятия',
    label: 'Северобайкальск',
  },
  {
    region: 'Бурятия',
    label: 'Селенгинск',
  },
  {
    region: 'Бурятия',
    label: 'Сосново-Озерское',
  },
  {
    region: 'Бурятия',
    label: 'Таксимо',
  },
  {
    region: 'Бурятия',
    label: 'Турунтаево',
  },
  {
    region: 'Бурятия',
    label: 'Улан-Удэ',
  },
  {
    region: 'Бурятия',
    label: 'Хоринск',
  },
  {
    region: 'Владимирская обл.',
    label: 'Александров',
  },
  {
    region: 'Владимирская обл.',
    label: 'Андреево',
  },
  {
    region: 'Владимирская обл.',
    label: 'Анопино',
  },
  {
    region: 'Владимирская обл.',
    label: 'Бавлены',
  },
  {
    region: 'Владимирская обл.',
    label: 'Балакирево',
  },
  {
    region: 'Владимирская обл.',
    label: 'Боголюбово',
  },
  {
    region: 'Владимирская обл.',
    label: 'Великодворский',
  },
  {
    region: 'Владимирская обл.',
    label: 'Вербовский',
  },
  {
    region: 'Владимирская обл.',
    label: 'Владимир',
  },
  {
    region: 'Владимирская обл.',
    label: 'Вязники',
  },
  {
    region: 'Владимирская обл.',
    label: 'Городищи',
  },
  {
    region: 'Владимирская обл.',
    label: 'Гороховец',
  },
  {
    region: 'Владимирская обл.',
    label: 'Гусевский',
  },
  {
    region: 'Владимирская обл.',
    label: 'Гусь Хрустальный',
  },
  {
    region: 'Владимирская обл.',
    label: 'Золотково',
  },
  {
    region: 'Владимирская обл.',
    label: 'Иванищи',
  },
  {
    region: 'Владимирская обл.',
    label: 'Камешково',
  },
  {
    region: 'Владимирская обл.',
    label: 'Карабаново',
  },
  {
    region: 'Владимирская обл.',
    label: 'Киржач',
  },
  {
    region: 'Владимирская обл.',
    label: 'Ковров',
  },
  {
    region: 'Владимирская обл.',
    label: 'Кольчугино',
  },
  {
    region: 'Владимирская обл.',
    label: 'Красная Горбатка',
  },
  {
    region: 'Владимирская обл.',
    label: 'Меленки',
  },
  {
    region: 'Владимирская обл.',
    label: 'Муром',
  },
  {
    region: 'Владимирская обл.',
    label: 'Петушки',
  },
  {
    region: 'Владимирская обл.',
    label: 'Покров',
  },
  {
    region: 'Владимирская обл.',
    label: 'Собинка',
  },
  {
    region: 'Владимирская обл.',
    label: 'Судогда',
  },
  {
    region: 'Владимирская обл.',
    label: 'Суздаль',
  },
  {
    region: 'Владимирская обл.',
    label: 'Юрьев-Польский',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Алексеевская',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Алущевск',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Быково',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Волгоград',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Волжский',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Городище',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Дубовка',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Елань',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Жирновск',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Иловля',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Калач-на-Дону',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Камышин',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Кириллов',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Клетский',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Котельниково',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Котово',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Кумылженская',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Ленинск',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Михайловка',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Нехаевский',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Николаевск',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Новоаннинский',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Новониколаевский',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Ольховка',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Палласовка',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Рудня',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Светлый Яр',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Серафимович',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Средняя Ахтуба',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Сталинград',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Старая Полтавка',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Суровикино',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Урюпинск',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Фролово',
  },
  {
    region: 'Волгоградская обл.',
    label: 'Чернышковский',
  },
  {
    region: 'Вологодская обл.',
    label: 'Бабаево',
  },
  {
    region: 'Вологодская обл.',
    label: 'Белозерск',
  },
  {
    region: 'Вологодская обл.',
    label: 'Великий Устюг',
  },
  {
    region: 'Вологодская обл.',
    label: 'Верховажье',
  },
  {
    region: 'Вологодская обл.',
    label: 'Вожега',
  },
  {
    region: 'Вологодская обл.',
    label: 'Вологда',
  },
  {
    region: 'Вологодская обл.',
    label: 'Вохтога',
  },
  {
    region: 'Вологодская обл.',
    label: 'Вытегра',
  },
  {
    region: 'Вологодская обл.',
    label: 'Грязовец',
  },
  {
    region: 'Вологодская обл.',
    label: 'Кадников',
  },
  {
    region: 'Вологодская обл.',
    label: 'Кадуй',
  },
  {
    region: 'Вологодская обл.',
    label: 'Кичменгский Городок',
  },
  {
    region: 'Вологодская обл.',
    label: 'Липин Бор',
  },
  {
    region: 'Вологодская обл.',
    label: 'Никольск',
  },
  {
    region: 'Вологодская обл.',
    label: 'Нюксеница',
  },
  {
    region: 'Вологодская обл.',
    label: 'Сокол',
  },
  {
    region: 'Вологодская обл.',
    label: 'Сямжа',
  },
  {
    region: 'Вологодская обл.',
    label: 'Тарногский Городок',
  },
  {
    region: 'Вологодская обл.',
    label: 'Тотьма',
  },
  {
    region: 'Вологодская обл.',
    label: 'Устюжна',
  },
  {
    region: 'Вологодская обл.',
    label: 'Харовск',
  },
  {
    region: 'Вологодская обл.',
    label: 'Чагода',
  },
  {
    region: 'Вологодская обл.',
    label: 'Череповец',
  },
  {
    region: 'Вологодская обл.',
    label: 'Шексна',
  },
  {
    region: 'Вологодская обл.',
    label: 'Шуйское',
  },
  {
    region: 'Воронежская обл.',
    label: 'Анна',
  },
  {
    region: 'Воронежская обл.',
    label: 'Бобров',
  },
  {
    region: 'Воронежская обл.',
    label: 'Богучар',
  },
  {
    region: 'Воронежская обл.',
    label: 'Борисоглебск',
  },
  {
    region: 'Воронежская обл.',
    label: 'Бутурлиновка',
  },
  {
    region: 'Воронежская обл.',
    label: 'Верхний Мамон',
  },
  {
    region: 'Воронежская обл.',
    label: 'Верхняя Хава',
  },
  {
    region: 'Воронежская обл.',
    label: 'Воробьевка',
  },
  {
    region: 'Воронежская обл.',
    label: 'Воронеж',
  },
  {
    region: 'Воронежская обл.',
    label: 'Грибановский',
  },
  {
    region: 'Воронежская обл.',
    label: 'Давыдовка',
  },
  {
    region: 'Воронежская обл.',
    label: 'Елань-Коленовский',
  },
  {
    region: 'Воронежская обл.',
    label: 'Калач',
  },
  {
    region: 'Воронежская обл.',
    label: 'Кантемировка',
  },
  {
    region: 'Воронежская обл.',
    label: 'Лиски',
  },
  {
    region: 'Воронежская обл.',
    label: 'Нижнедевицк',
  },
  {
    region: 'Воронежская обл.',
    label: 'Новая Усмань',
  },
  {
    region: 'Воронежская обл.',
    label: 'Новохоперск',
  },
  {
    region: 'Воронежская обл.',
    label: 'Ольховатка',
  },
  {
    region: 'Воронежская обл.',
    label: 'Острогожск',
  },
  {
    region: 'Воронежская обл.',
    label: 'Павловск',
  },
  {
    region: 'Воронежская обл.',
    label: 'Панино',
  },
  {
    region: 'Воронежская обл.',
    label: 'Петропавловка',
  },
  {
    region: 'Воронежская обл.',
    label: 'Поворино',
  },
  {
    region: 'Воронежская обл.',
    label: 'Подгоренский',
  },
  {
    region: 'Воронежская обл.',
    label: 'Рамонь',
  },
  {
    region: 'Воронежская обл.',
    label: 'Репьевка',
  },
  {
    region: 'Воронежская обл.',
    label: 'Россошь',
  },
  {
    region: 'Воронежская обл.',
    label: 'Семилуки',
  },
  {
    region: 'Воронежская обл.',
    label: 'Таловая',
  },
  {
    region: 'Воронежская обл.',
    label: 'Терновка',
  },
  {
    region: 'Воронежская обл.',
    label: 'Хохольский',
  },
  {
    region: 'Воронежская обл.',
    label: 'Эртиль',
  },
  {
    region: 'Воронежская обл.',
    label: 'нововоронеж',
  },
  {
    region: 'Дагестан',
    label: 'Агвали',
  },
  {
    region: 'Дагестан',
    label: 'Акуша',
  },
  {
    region: 'Дагестан',
    label: 'Ахты',
  },
  {
    region: 'Дагестан',
    label: 'Ачису',
  },
  {
    region: 'Дагестан',
    label: 'Бабаюрт',
  },
  {
    region: 'Дагестан',
    label: 'Бежта',
  },
  {
    region: 'Дагестан',
    label: 'Ботлих',
  },
  {
    region: 'Дагестан',
    label: 'Буйнакск',
  },
  {
    region: 'Дагестан',
    label: 'Вачи',
  },
  {
    region: 'Дагестан',
    label: 'Гергебиль',
  },
  {
    region: 'Дагестан',
    label: 'Гуниб',
  },
  {
    region: 'Дагестан',
    label: 'Дагестанские Огни',
  },
  {
    region: 'Дагестан',
    label: 'Дербент',
  },
  {
    region: 'Дагестан',
    label: 'Дылым',
  },
  {
    region: 'Дагестан',
    label: 'Ершовка',
  },
  {
    region: 'Дагестан',
    label: 'Избербаш',
  },
  {
    region: 'Дагестан',
    label: 'Карабудахкент',
  },
  {
    region: 'Дагестан',
    label: 'Карата',
  },
  {
    region: 'Дагестан',
    label: 'Каспийск',
  },
  {
    region: 'Дагестан',
    label: 'Касумкент',
  },
  {
    region: 'Дагестан',
    label: 'Кизилюрт',
  },
  {
    region: 'Дагестан',
    label: 'Кизляр',
  },
  {
    region: 'Дагестан',
    label: 'Кочубей',
  },
  {
    region: 'Дагестан',
    label: 'Кумух',
  },
  {
    region: 'Дагестан',
    label: 'Курах',
  },
  {
    region: 'Дагестан',
    label: 'Магарамкент',
  },
  {
    region: 'Дагестан',
    label: 'Маджалис',
  },
  {
    region: 'Дагестан',
    label: 'Махачкала',
  },
  {
    region: 'Дагестан',
    label: 'Мехельта',
  },
  {
    region: 'Дагестан',
    label: 'Новолакское',
  },
  {
    region: 'Дагестан',
    label: 'Рутул',
  },
  {
    region: 'Дагестан',
    label: 'Советское',
  },
  {
    region: 'Дагестан',
    label: 'Тарумовка',
  },
  {
    region: 'Дагестан',
    label: 'Терекли-Мектеб',
  },
  {
    region: 'Дагестан',
    label: 'Тлярата',
  },
  {
    region: 'Дагестан',
    label: 'Тпиг',
  },
  {
    region: 'Дагестан',
    label: 'Уркарах',
  },
  {
    region: 'Дагестан',
    label: 'Хасавюрт',
  },
  {
    region: 'Дагестан',
    label: 'Хив',
  },
  {
    region: 'Дагестан',
    label: 'Хунзах',
  },
  {
    region: 'Дагестан',
    label: 'Цуриб',
  },
  {
    region: 'Дагестан',
    label: 'Южно-Сухокумск',
  },
  {
    region: 'Еврейская обл.',
    label: 'Биробиджан',
  },
  {
    region: 'Ивановская обл.',
    label: 'Архиповка',
  },
  {
    region: 'Ивановская обл.',
    label: 'Верхний Ландех',
  },
  {
    region: 'Ивановская обл.',
    label: 'Вичуга',
  },
  {
    region: 'Ивановская обл.',
    label: 'Гаврилов Посад',
  },
  {
    region: 'Ивановская обл.',
    label: 'Долматовский',
  },
  {
    region: 'Ивановская обл.',
    label: 'Дуляпино',
  },
  {
    region: 'Ивановская обл.',
    label: 'Заволжск',
  },
  {
    region: 'Ивановская обл.',
    label: 'Заречный',
  },
  {
    region: 'Ивановская обл.',
    label: 'Иваново',
  },
  {
    region: 'Ивановская обл.',
    label: 'Иваньковский',
  },
  {
    region: 'Ивановская обл.',
    label: 'Ильинское-Хованское',
  },
  {
    region: 'Ивановская обл.',
    label: 'Каминский',
  },
  {
    region: 'Ивановская обл.',
    label: 'Кинешма',
  },
  {
    region: 'Ивановская обл.',
    label: 'Комсомольск',
  },
  {
    region: 'Ивановская обл.',
    label: 'Кохма',
  },
  {
    region: 'Ивановская обл.',
    label: 'Лух',
  },
  {
    region: 'Ивановская обл.',
    label: 'Палех',
  },
  {
    region: 'Ивановская обл.',
    label: 'Пестяки',
  },
  {
    region: 'Ивановская обл.',
    label: 'Приволжск',
  },
  {
    region: 'Ивановская обл.',
    label: 'Пучеж',
  },
  {
    region: 'Ивановская обл.',
    label: 'Родники',
  },
  {
    region: 'Ивановская обл.',
    label: 'Савино',
  },
  {
    region: 'Ивановская обл.',
    label: 'Сокольское',
  },
  {
    region: 'Ивановская обл.',
    label: 'Тейково',
  },
  {
    region: 'Ивановская обл.',
    label: 'Фурманов',
  },
  {
    region: 'Ивановская обл.',
    label: 'Шуя',
  },
  {
    region: 'Ивановская обл.',
    label: 'Южа',
  },
  {
    region: 'Ивановская обл.',
    label: 'Юрьевец',
  },
  {
    region: 'Иркутская обл.',
    label: 'Алексеевск',
  },
  {
    region: 'Иркутская обл.',
    label: 'Алзамай',
  },
  {
    region: 'Иркутская обл.',
    label: 'Алыгжер',
  },
  {
    region: 'Иркутская обл.',
    label: 'Ангарск',
  },
  {
    region: 'Иркутская обл.',
    label: 'Артемовский',
  },
  {
    region: 'Иркутская обл.',
    label: 'Атагай',
  },
  {
    region: 'Иркутская обл.',
    label: 'Байкал',
  },
  {
    region: 'Иркутская обл.',
    label: 'Байкальск',
  },
  {
    region: 'Иркутская обл.',
    label: 'Балаганск',
  },
  {
    region: 'Иркутская обл.',
    label: 'Баяндай',
  },
  {
    region: 'Иркутская обл.',
    label: 'Бирюсинск',
  },
  {
    region: 'Иркутская обл.',
    label: 'Бодайбо',
  },
  {
    region: 'Иркутская обл.',
    label: 'Большая Речка',
  },
  {
    region: 'Иркутская обл.',
    label: 'Большой Луг',
  },
  {
    region: 'Иркутская обл.',
    label: 'Бохан',
  },
  {
    region: 'Иркутская обл.',
    label: 'Братск',
  },
  {
    region: 'Иркутская обл.',
    label: 'Видим',
  },
  {
    region: 'Иркутская обл.',
    label: 'Витимский',
  },
  {
    region: 'Иркутская обл.',
    label: 'Вихоревка',
  },
  {
    region: 'Иркутская обл.',
    label: 'Еланцы',
  },
  {
    region: 'Иркутская обл.',
    label: 'Ербогачен',
  },
  {
    region: 'Иркутская обл.',
    label: 'Железногорск-Илимский',
  },
  {
    region: 'Иркутская обл.',
    label: 'Жигалово',
  },
  {
    region: 'Иркутская обл.',
    label: 'Забитуй',
  },
  {
    region: 'Иркутская обл.',
    label: 'Залари',
  },
  {
    region: 'Иркутская обл.',
    label: 'Звездный',
  },
  {
    region: 'Иркутская обл.',
    label: 'Зима',
  },
  {
    region: 'Иркутская обл.',
    label: 'Иркутск',
  },
  {
    region: 'Иркутская обл.',
    label: 'Казачинское',
  },
  {
    region: 'Иркутская обл.',
    label: 'Качуг',
  },
  {
    region: 'Иркутская обл.',
    label: 'Квиток',
  },
  {
    region: 'Иркутская обл.',
    label: 'Киренск',
  },
  {
    region: 'Иркутская обл.',
    label: 'Куйтун',
  },
  {
    region: 'Иркутская обл.',
    label: 'Култук',
  },
  {
    region: 'Иркутская обл.',
    label: 'Кутулик',
  },
  {
    region: 'Иркутская обл.',
    label: 'Мама',
  },
  {
    region: 'Иркутская обл.',
    label: 'Нижнеудинск',
  },
  {
    region: 'Иркутская обл.',
    label: 'Оса',
  },
  {
    region: 'Иркутская обл.',
    label: 'Саянск',
  },
  {
    region: 'Иркутская обл.',
    label: 'Слюдянка',
  },
  {
    region: 'Иркутская обл.',
    label: 'Тайшет',
  },
  {
    region: 'Иркутская обл.',
    label: 'Тулун',
  },
  {
    region: 'Иркутская обл.',
    label: 'Усолье-Сибирское',
  },
  {
    region: 'Иркутская обл.',
    label: 'Усть-Илимск',
  },
  {
    region: 'Иркутская обл.',
    label: 'Усть-Кут',
  },
  {
    region: 'Иркутская обл.',
    label: 'Усть-Ордынский',
  },
  {
    region: 'Иркутская обл.',
    label: 'Усть-Уда',
  },
  {
    region: 'Иркутская обл.',
    label: 'Черемхово',
  },
  {
    region: 'Иркутская обл.',
    label: 'Чунский',
  },
  {
    region: 'Иркутская обл.',
    label: 'Шелехов',
  },
  {
    region: 'Кабардино-Балкария',
    label: 'Баксан',
  },
  {
    region: 'Кабардино-Балкария',
    label: 'Майский',
  },
  {
    region: 'Кабардино-Балкария',
    label: 'Нальчик',
  },
  {
    region: 'Кабардино-Балкария',
    label: 'Нарткала',
  },
  {
    region: 'Кабардино-Балкария',
    label: 'Прохладный',
  },
  {
    region: 'Кабардино-Балкария',
    label: 'Советское',
  },
  {
    region: 'Кабардино-Балкария',
    label: 'Терек',
  },
  {
    region: 'Кабардино-Балкария',
    label: 'Тырныауз',
  },
  {
    region: 'Кабардино-Балкария',
    label: 'Чегем-Первый',
  },
  {
    region: 'Калининградская обл.',
    label: 'Багратионовск',
  },
  {
    region: 'Калининградская обл.',
    label: 'Балтийск',
  },
  {
    region: 'Калининградская обл.',
    label: 'Гвардейск',
  },
  {
    region: 'Калининградская обл.',
    label: 'Гурьевск',
  },
  {
    region: 'Калининградская обл.',
    label: 'Гусев',
  },
  {
    region: 'Калининградская обл.',
    label: 'Железнодорожный',
  },
  {
    region: 'Калининградская обл.',
    label: 'Зеленоградск',
  },
  {
    region: 'Калининградская обл.',
    label: 'Знаменск',
  },
  {
    region: 'Калининградская обл.',
    label: 'Кёнигсберг',
  },
  {
    region: 'Калининградская обл.',
    label: 'Калининград',
  },
  {
    region: 'Калининградская обл.',
    label: 'Кенисберг',
  },
  {
    region: 'Калининградская обл.',
    label: 'Краснознаменск',
  },
  {
    region: 'Калининградская обл.',
    label: 'Мамоново',
  },
  {
    region: 'Калининградская обл.',
    label: 'Неман',
  },
  {
    region: 'Калининградская обл.',
    label: 'Нестеров',
  },
  {
    region: 'Калининградская обл.',
    label: 'Озерск',
  },
  {
    region: 'Калининградская обл.',
    label: 'Полесск',
  },
  {
    region: 'Калининградская обл.',
    label: 'Правдинск',
  },
  {
    region: 'Калининградская обл.',
    label: 'Светлогорск',
  },
  {
    region: 'Калининградская обл.',
    label: 'Светлый',
  },
  {
    region: 'Калининградская обл.',
    label: 'Славск',
  },
  {
    region: 'Калининградская обл.',
    label: 'Советск',
  },
  {
    region: 'Калининградская обл.',
    label: 'Черняховск',
  },
  {
    region: 'Калмыкия',
    label: 'Аршань',
  },
  {
    region: 'Калмыкия',
    label: 'Каспийский',
  },
  {
    region: 'Калмыкия',
    label: 'Комсомольский',
  },
  {
    region: 'Калмыкия',
    label: 'Малые Дербеты',
  },
  {
    region: 'Калмыкия',
    label: 'Приютное',
  },
  {
    region: 'Калмыкия',
    label: 'Советское',
  },
  {
    region: 'Калмыкия',
    label: 'Троицкое',
  },
  {
    region: 'Калмыкия',
    label: 'Утта',
  },
  {
    region: 'Калмыкия',
    label: 'Цаган-Аман',
  },
  {
    region: 'Калмыкия',
    label: 'Элиста',
  },
  {
    region: 'Калмыкия',
    label: 'Юста',
  },
  {
    region: 'Калмыкия',
    label: 'Яшалта',
  },
  {
    region: 'Калмыкия',
    label: 'Яшкуль',
  },
  {
    region: 'Калужская обл.',
    label: 'Бабынино',
  },
  {
    region: 'Калужская обл.',
    label: 'Балабаново',
  },
  {
    region: 'Калужская обл.',
    label: 'Барятино',
  },
  {
    region: 'Калужская обл.',
    label: 'Белоусово',
  },
  {
    region: 'Калужская обл.',
    label: 'Бетлица',
  },
  {
    region: 'Калужская обл.',
    label: 'Боровск',
  },
  {
    region: 'Калужская обл.',
    label: 'Дугна',
  },
  {
    region: 'Калужская обл.',
    label: 'Дудоровский',
  },
  {
    region: 'Калужская обл.',
    label: 'Думиничи',
  },
  {
    region: 'Калужская обл.',
    label: 'Еленский',
  },
  {
    region: 'Калужская обл.',
    label: 'Жиздра',
  },
  {
    region: 'Калужская обл.',
    label: 'Износки',
  },
  {
    region: 'Калужская обл.',
    label: 'Калуга',
  },
  {
    region: 'Калужская обл.',
    label: 'Киров',
  },
  {
    region: 'Калужская обл.',
    label: 'Козельск',
  },
  {
    region: 'Калужская обл.',
    label: 'Кондрово',
  },
  {
    region: 'Калужская обл.',
    label: 'Людиново',
  },
  {
    region: 'Калужская обл.',
    label: 'Малоярославец',
  },
  {
    region: 'Калужская обл.',
    label: 'Медынь',
  },
  {
    region: 'Калужская обл.',
    label: 'Мещовск',
  },
  {
    region: 'Калужская обл.',
    label: 'Мосальск',
  },
  {
    region: 'Калужская обл.',
    label: 'Обнинск',
  },
  {
    region: 'Калужская обл.',
    label: 'Перемышль',
  },
  {
    region: 'Калужская обл.',
    label: 'Спас-Деменск',
  },
  {
    region: 'Калужская обл.',
    label: 'Сухиничи',
  },
  {
    region: 'Калужская обл.',
    label: 'Таруса',
  },
  {
    region: 'Калужская обл.',
    label: 'Ульяново',
  },
  {
    region: 'Калужская обл.',
    label: 'Ферзиково',
  },
  {
    region: 'Калужская обл.',
    label: 'Хвастовичи',
  },
  {
    region: 'Калужская обл.',
    label: 'Юхнов',
  },
  {
    region: 'Камчатская обл.',
    label: 'Атласово',
  },
  {
    region: 'Камчатская обл.',
    label: 'Аянка',
  },
  {
    region: 'Камчатская обл.',
    label: 'Большерецк',
  },
  {
    region: 'Камчатская обл.',
    label: 'Вилючинск',
  },
  {
    region: 'Камчатская обл.',
    label: 'Елизово',
  },
  {
    region: 'Камчатская обл.',
    label: 'Ильпырский',
  },
  {
    region: 'Камчатская обл.',
    label: 'Каменское',
  },
  {
    region: 'Камчатская обл.',
    label: 'Кировский',
  },
  {
    region: 'Камчатская обл.',
    label: 'Ключи',
  },
  {
    region: 'Камчатская обл.',
    label: 'Крапивная',
  },
  {
    region: 'Камчатская обл.',
    label: 'Мильково',
  },
  {
    region: 'Камчатская обл.',
    label: 'Никольское',
  },
  {
    region: 'Камчатская обл.',
    label: 'Озерновский',
  },
  {
    region: 'Камчатская обл.',
    label: 'Оссора',
  },
  {
    region: 'Камчатская обл.',
    label: 'Палана',
  },
  {
    region: 'Камчатская обл.',
    label: 'Парень',
  },
  {
    region: 'Камчатская обл.',
    label: 'Пахачи',
  },
  {
    region: 'Камчатская обл.',
    label: 'Петропавловск-Камчатский',
  },
  {
    region: 'Камчатская обл.',
    label: 'Тигиль',
  },
  {
    region: 'Камчатская обл.',
    label: 'Тиличики',
  },
  {
    region: 'Камчатская обл.',
    label: 'Усть-Большерецк',
  },
  {
    region: 'Камчатская обл.',
    label: 'Усть-Камчатск',
  },
  {
    region: 'Карелия',
    label: 'Амбарный',
  },
  {
    region: 'Карелия',
    label: 'Беломорск',
  },
  {
    region: 'Карелия',
    label: 'Валаам',
  },
  {
    region: 'Карелия',
    label: 'Вирандозеро',
  },
  {
    region: 'Карелия',
    label: 'Гирвас',
  },
  {
    region: 'Карелия',
    label: 'Деревянка',
  },
  {
    region: 'Карелия',
    label: 'Идель',
  },
  {
    region: 'Карелия',
    label: 'Ильинский',
  },
  {
    region: 'Карелия',
    label: 'Импалахти',
  },
  {
    region: 'Карелия',
    label: 'Калевала',
  },
  {
    region: 'Карелия',
    label: 'Кемь',
  },
  {
    region: 'Карелия',
    label: 'Кестеньга',
  },
  {
    region: 'Карелия',
    label: 'Кондопога',
  },
  {
    region: 'Карелия',
    label: 'Костомукша',
  },
  {
    region: 'Карелия',
    label: 'Лахденпохья',
  },
  {
    region: 'Карелия',
    label: 'Лоухи',
  },
  {
    region: 'Карелия',
    label: 'Медвежьегорск',
  },
  {
    region: 'Карелия',
    label: 'Муезерский',
  },
  {
    region: 'Карелия',
    label: 'Олонец',
  },
  {
    region: 'Карелия',
    label: 'Петрозаводск',
  },
  {
    region: 'Карелия',
    label: 'Питкяранта',
  },
  {
    region: 'Карелия',
    label: 'Повенец',
  },
  {
    region: 'Карелия',
    label: 'Пряжа',
  },
  {
    region: 'Карелия',
    label: 'Пудож',
  },
  {
    region: 'Карелия',
    label: 'Сегежа',
  },
  {
    region: 'Карелия',
    label: 'Сортавала',
  },
  {
    region: 'Карелия',
    label: 'Софпорог',
  },
  {
    region: 'Карелия',
    label: 'Суоярви',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Анжеро-Судженск',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Барзас',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Белово',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Белогорск',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Березовский',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Грамотеино',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Гурьевск',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Ижморский',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Итатский',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Калтан',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Кедровка',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Кемерово',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Киселевск',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Крапивинский',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Ленинск-Кузнецкий',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Мариинск',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Междуреченск',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Мыски',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Новокузнецк',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Осинники',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Прокопьевск',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Промышленная',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Тайга',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Таштагол',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Тисуль',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Топки',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Тяжинский',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Юрга',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Яшкино',
  },
  {
    region: 'Кемеровская обл.',
    label: 'Яя',
  },
  {
    region: 'Кировская обл.',
    label: 'Арбаж',
  },
  {
    region: 'Кировская обл.',
    label: 'Аркуль',
  },
  {
    region: 'Кировская обл.',
    label: 'Белая Холуница',
  },
  {
    region: 'Кировская обл.',
    label: 'Богородское',
  },
  {
    region: 'Кировская обл.',
    label: 'Боровой',
  },
  {
    region: 'Кировская обл.',
    label: 'Верхошижемье',
  },
  {
    region: 'Кировская обл.',
    label: 'Вятские Поляны',
  },
  {
    region: 'Кировская обл.',
    label: 'Зуевка',
  },
  {
    region: 'Кировская обл.',
    label: 'Каринторф',
  },
  {
    region: 'Кировская обл.',
    label: 'Кикнур',
  },
  {
    region: 'Кировская обл.',
    label: 'Кильмезь',
  },
  {
    region: 'Кировская обл.',
    label: 'Киров',
  },
  {
    region: 'Кировская обл.',
    label: 'Кирово-Чепецк',
  },
  {
    region: 'Кировская обл.',
    label: 'Кирс',
  },
  {
    region: 'Кировская обл.',
    label: 'Кобра',
  },
  {
    region: 'Кировская обл.',
    label: 'Котельнич',
  },
  {
    region: 'Кировская обл.',
    label: 'Кумены',
  },
  {
    region: 'Кировская обл.',
    label: 'Ленинское',
  },
  {
    region: 'Кировская обл.',
    label: 'Луза',
  },
  {
    region: 'Кировская обл.',
    label: 'Малмыж',
  },
  {
    region: 'Кировская обл.',
    label: 'Мураши',
  },
  {
    region: 'Кировская обл.',
    label: 'Нагорск',
  },
  {
    region: 'Кировская обл.',
    label: 'Нема',
  },
  {
    region: 'Кировская обл.',
    label: 'Нововятск',
  },
  {
    region: 'Кировская обл.',
    label: 'Нолинск',
  },
  {
    region: 'Кировская обл.',
    label: 'Омутнинск',
  },
  {
    region: 'Кировская обл.',
    label: 'Опарино',
  },
  {
    region: 'Кировская обл.',
    label: 'Оричи',
  },
  {
    region: 'Кировская обл.',
    label: 'Пижанка',
  },
  {
    region: 'Кировская обл.',
    label: 'Подосиновец',
  },
  {
    region: 'Кировская обл.',
    label: 'Санчурск',
  },
  {
    region: 'Кировская обл.',
    label: 'Свеча',
  },
  {
    region: 'Кировская обл.',
    label: 'Слободской',
  },
  {
    region: 'Кировская обл.',
    label: 'Советск',
  },
  {
    region: 'Кировская обл.',
    label: 'Суна',
  },
  {
    region: 'Кировская обл.',
    label: 'Тужа',
  },
  {
    region: 'Кировская обл.',
    label: 'Уни',
  },
  {
    region: 'Кировская обл.',
    label: 'Уржум',
  },
  {
    region: 'Кировская обл.',
    label: 'Фаленки',
  },
  {
    region: 'Кировская обл.',
    label: 'Халтурин',
  },
  {
    region: 'Кировская обл.',
    label: 'Юрья',
  },
  {
    region: 'Кировская обл.',
    label: 'Яранск',
  },
  {
    region: 'Коми',
    label: 'Абезь',
  },
  {
    region: 'Коми',
    label: 'Айкино',
  },
  {
    region: 'Коми',
    label: 'Верхняя Инта',
  },
  {
    region: 'Коми',
    label: 'Визинга',
  },
  {
    region: 'Коми',
    label: 'Водный',
  },
  {
    region: 'Коми',
    label: 'Вожаель',
  },
  {
    region: 'Коми',
    label: 'Воркута',
  },
  {
    region: 'Коми',
    label: 'Вуктыл',
  },
  {
    region: 'Коми',
    label: 'Гешарт',
  },
  {
    region: 'Коми',
    label: 'Елецкий',
  },
  {
    region: 'Коми',
    label: 'Емва',
  },
  {
    region: 'Коми',
    label: 'Заполярный',
  },
  {
    region: 'Коми',
    label: 'Ижма',
  },
  {
    region: 'Коми',
    label: 'Инта',
  },
  {
    region: 'Коми',
    label: 'Ираель',
  },
  {
    region: 'Коми',
    label: 'Каджером',
  },
  {
    region: 'Коми',
    label: 'Кажым',
  },
  {
    region: 'Коми',
    label: 'Кожым',
  },
  {
    region: 'Коми',
    label: 'Койгородок',
  },
  {
    region: 'Коми',
    label: 'Корткерос',
  },
  {
    region: 'Коми',
    label: 'Кослан',
  },
  {
    region: 'Коми',
    label: 'Объячево',
  },
  {
    region: 'Коми',
    label: 'Печора',
  },
  {
    region: 'Коми',
    label: 'Сосногорск',
  },
  {
    region: 'Коми',
    label: 'Сыктывкар',
  },
  {
    region: 'Коми',
    label: 'Троицко-Печерск',
  },
  {
    region: 'Коми',
    label: 'Усинск',
  },
  {
    region: 'Коми',
    label: 'Усогорск',
  },
  {
    region: 'Коми',
    label: 'Усть-Кулом',
  },
  {
    region: 'Коми',
    label: 'Усть-Цильма',
  },
  {
    region: 'Коми',
    label: 'Ухта',
  },
  {
    region: 'Костромская обл.',
    label: 'Антропово',
  },
  {
    region: 'Костромская обл.',
    label: 'Боговарово',
  },
  {
    region: 'Костромская обл.',
    label: 'Буй',
  },
  {
    region: 'Костромская обл.',
    label: 'Волгореченск',
  },
  {
    region: 'Костромская обл.',
    label: 'Галич',
  },
  {
    region: 'Костромская обл.',
    label: 'Горчуха',
  },
  {
    region: 'Костромская обл.',
    label: 'Зебляки',
  },
  {
    region: 'Костромская обл.',
    label: 'Кадый',
  },
  {
    region: 'Костромская обл.',
    label: 'Кологрив',
  },
  {
    region: 'Костромская обл.',
    label: 'Кострома',
  },
  {
    region: 'Костромская обл.',
    label: 'Красное-на-Волге',
  },
  {
    region: 'Костромская обл.',
    label: 'Макарьев',
  },
  {
    region: 'Костромская обл.',
    label: 'Мантурово',
  },
  {
    region: 'Костромская обл.',
    label: 'Нерехта',
  },
  {
    region: 'Костромская обл.',
    label: 'Нея',
  },
  {
    region: 'Костромская обл.',
    label: 'Островское',
  },
  {
    region: 'Костромская обл.',
    label: 'Павино',
  },
  {
    region: 'Костромская обл.',
    label: 'Парфентьево',
  },
  {
    region: 'Костромская обл.',
    label: 'Поназырево',
  },
  {
    region: 'Костромская обл.',
    label: 'Солигалич',
  },
  {
    region: 'Костромская обл.',
    label: 'Судиславль',
  },
  {
    region: 'Костромская обл.',
    label: 'Сусанино',
  },
  {
    region: 'Костромская обл.',
    label: 'Чухлома',
  },
  {
    region: 'Костромская обл.',
    label: 'Шарья',
  },
  {
    region: 'Костромская обл.',
    label: 'Шемятино',
  },
  {
    region: 'Краснодарский край',
    label: 'Абинск',
  },
  {
    region: 'Краснодарский край',
    label: 'Абрау-Дюрсо',
  },
  {
    region: 'Краснодарский край',
    label: 'Анапа',
  },
  {
    region: 'Краснодарский край',
    label: 'Апшеронск',
  },
  {
    region: 'Краснодарский край',
    label: 'Армавир',
  },
  {
    region: 'Краснодарский край',
    label: 'Архипо-Осиповка',
  },
  {
    region: 'Краснодарский край',
    label: 'Афипский',
  },
  {
    region: 'Краснодарский край',
    label: 'Ахтырский',
  },
  {
    region: 'Краснодарский край',
    label: 'Ачуево',
  },
  {
    region: 'Краснодарский край',
    label: 'Белореченск',
  },
  {
    region: 'Краснодарский край',
    label: 'Верхнебаканский',
  },
  {
    region: 'Краснодарский край',
    label: 'Выселки',
  },
  {
    region: 'Краснодарский край',
    label: 'Геленджик',
  },
  {
    region: 'Краснодарский край',
    label: 'Гиагинская',
  },
  {
    region: 'Краснодарский край',
    label: 'Горячий Ключ',
  },
  {
    region: 'Краснодарский край',
    label: 'Джубга',
  },
  {
    region: 'Краснодарский край',
    label: 'Динская',
  },
  {
    region: 'Краснодарский край',
    label: 'Ейск',
  },
  {
    region: 'Краснодарский край',
    label: 'Ильский',
  },
  {
    region: 'Краснодарский край',
    label: 'Кабардинка',
  },
  {
    region: 'Краснодарский край',
    label: 'Калинино',
  },
  {
    region: 'Краснодарский край',
    label: 'Калининская',
  },
  {
    region: 'Краснодарский край',
    label: 'Каменномостский',
  },
  {
    region: 'Краснодарский край',
    label: 'Каневская',
  },
  {
    region: 'Краснодарский край',
    label: 'Кореновск',
  },
  {
    region: 'Краснодарский край',
    label: 'Красноармейская',
  },
  {
    region: 'Краснодарский край',
    label: 'Краснодар',
  },
  {
    region: 'Краснодарский край',
    label: 'Кропоткин',
  },
  {
    region: 'Краснодарский край',
    label: 'Крыловская',
  },
  {
    region: 'Краснодарский край',
    label: 'Крымск',
  },
  {
    region: 'Краснодарский край',
    label: 'Курганинск',
  },
  {
    region: 'Краснодарский край',
    label: 'Кущевская',
  },
  {
    region: 'Краснодарский край',
    label: 'Лабинск',
  },
  {
    region: 'Краснодарский край',
    label: 'Лениградская',
  },
  {
    region: 'Краснодарский край',
    label: 'Майкоп',
  },
  {
    region: 'Краснодарский край',
    label: 'Мостовской',
  },
  {
    region: 'Краснодарский край',
    label: 'Новороссийск',
  },
  {
    region: 'Краснодарский край',
    label: 'Отрадная',
  },
  {
    region: 'Краснодарский край',
    label: 'Павловская',
  },
  {
    region: 'Краснодарский край',
    label: 'Приморско-Ахтарск',
  },
  {
    region: 'Краснодарский край',
    label: 'Северская',
  },
  {
    region: 'Краснодарский край',
    label: 'Славянск-на-Кубани',
  },
  {
    region: 'Краснодарский край',
    label: 'Сочи',
  },
  {
    region: 'Краснодарский край',
    label: 'Староминская',
  },
  {
    region: 'Краснодарский край',
    label: 'Старощербиновская',
  },
  {
    region: 'Краснодарский край',
    label: 'Тбилисская',
  },
  {
    region: 'Краснодарский край',
    label: 'Темрюк',
  },
  {
    region: 'Краснодарский край',
    label: 'Тимашевск',
  },
  {
    region: 'Краснодарский край',
    label: 'Тихорецк',
  },
  {
    region: 'Краснодарский край',
    label: 'Туапсе',
  },
  {
    region: 'Краснодарский край',
    label: 'Тульский',
  },
  {
    region: 'Краснодарский край',
    label: 'Усть-Лабинск',
  },
  {
    region: 'Краснодарский край',
    label: 'Шовгеновский',
  },
  {
    region: 'Красноярский край',
    label: ' Железногорск',
  },
  {
    region: 'Красноярский край',
    label: 'Абаза',
  },
  {
    region: 'Красноярский край',
    label: 'Абакан',
  },
  {
    region: 'Красноярский край',
    label: 'Абан',
  },
  {
    region: 'Красноярский край',
    label: 'Агинское',
  },
  {
    region: 'Красноярский край',
    label: 'Артемовск',
  },
  {
    region: 'Красноярский край',
    label: 'Аскиз',
  },
  {
    region: 'Красноярский край',
    label: 'Ачинск',
  },
  {
    region: 'Красноярский край',
    label: 'Байкит',
  },
  {
    region: 'Красноярский край',
    label: 'Балахта',
  },
  {
    region: 'Красноярский край',
    label: 'Балыкса',
  },
  {
    region: 'Красноярский край',
    label: 'Белый Яр',
  },
  {
    region: 'Красноярский край',
    label: 'Бельтырский',
  },
  {
    region: 'Красноярский край',
    label: 'Бея',
  },
  {
    region: 'Красноярский край',
    label: 'Бискамжа',
  },
  {
    region: 'Красноярский край',
    label: 'Боготол',
  },
  {
    region: 'Красноярский край',
    label: 'Боград',
  },
  {
    region: 'Красноярский край',
    label: 'Богучаны',
  },
  {
    region: 'Красноярский край',
    label: 'Большая Мурта',
  },
  {
    region: 'Красноярский край',
    label: 'Большой Улуй',
  },
  {
    region: 'Красноярский край',
    label: 'Бородино',
  },
  {
    region: 'Красноярский край',
    label: 'Ванавара',
  },
  {
    region: 'Красноярский край',
    label: 'Верхнеимбатск',
  },
  {
    region: 'Красноярский край',
    label: 'Горячегорск',
  },
  {
    region: 'Красноярский край',
    label: 'Дзержинское',
  },
  {
    region: 'Красноярский край',
    label: 'Дивногорск',
  },
  {
    region: 'Красноярский край',
    label: 'Диксон',
  },
  {
    region: 'Красноярский край',
    label: 'Дудинка',
  },
  {
    region: 'Красноярский край',
    label: 'Емельяново',
  },
  {
    region: 'Красноярский край',
    label: 'Енисейск',
  },
  {
    region: 'Красноярский край',
    label: 'Ермаковское',
  },
  {
    region: 'Красноярский край',
    label: 'Заозерный',
  },
  {
    region: 'Красноярский край',
    label: 'Зеленогорск',
  },
  {
    region: 'Красноярский край',
    label: 'Игарка',
  },
  {
    region: 'Красноярский край',
    label: 'Идринское',
  },
  {
    region: 'Красноярский край',
    label: 'Иланский',
  },
  {
    region: 'Красноярский край',
    label: 'Ирбейское',
  },
  {
    region: 'Красноярский край',
    label: 'Казачинское',
  },
  {
    region: 'Красноярский край',
    label: 'Канск',
  },
  {
    region: 'Красноярский край',
    label: 'Каратузское',
  },
  {
    region: 'Красноярский край',
    label: 'Караул',
  },
  {
    region: 'Красноярский край',
    label: 'Кежма',
  },
  {
    region: 'Красноярский край',
    label: 'Кодинск',
  },
  {
    region: 'Красноярский край',
    label: 'Козулька',
  },
  {
    region: 'Красноярский край',
    label: 'Копьево',
  },
  {
    region: 'Красноярский край',
    label: 'Краснотуранск',
  },
  {
    region: 'Красноярский край',
    label: 'Красноярск',
  },
  {
    region: 'Красноярский край',
    label: 'Курагино',
  },
  {
    region: 'Красноярский край',
    label: 'Лесосибирск',
  },
  {
    region: 'Красноярский край',
    label: 'Минусинск',
  },
  {
    region: 'Красноярский край',
    label: 'Мотыгино',
  },
  {
    region: 'Красноярский край',
    label: 'Назарово',
  },
  {
    region: 'Красноярский край',
    label: 'Нижний Ингаш',
  },
  {
    region: 'Красноярский край',
    label: 'Новоселово',
  },
  {
    region: 'Красноярский край',
    label: 'Норильск',
  },
  {
    region: 'Красноярский край',
    label: 'Партизанское',
  },
  {
    region: 'Красноярский край',
    label: 'Пировское',
  },
  {
    region: 'Красноярский край',
    label: 'Саяногорск',
  },
  {
    region: 'Красноярский край',
    label: 'Северо-Енисейский',
  },
  {
    region: 'Красноярский край',
    label: 'Сосновоборск',
  },
  {
    region: 'Красноярский край',
    label: 'Тасеево',
  },
  {
    region: 'Красноярский край',
    label: 'Таштып',
  },
  {
    region: 'Красноярский край',
    label: 'Тура',
  },
  {
    region: 'Красноярский край',
    label: 'Туруханск',
  },
  {
    region: 'Красноярский край',
    label: 'Тюхтет',
  },
  {
    region: 'Красноярский край',
    label: 'Ужур',
  },
  {
    region: 'Красноярский край',
    label: 'Усть-Авам',
  },
  {
    region: 'Красноярский край',
    label: 'Уяр',
  },
  {
    region: 'Красноярский край',
    label: 'Хатанга',
  },
  {
    region: 'Красноярский край',
    label: 'Черемушки',
  },
  {
    region: 'Красноярский край',
    label: 'Черногорск',
  },
  {
    region: 'Красноярский край',
    label: 'Шалинское',
  },
  {
    region: 'Красноярский край',
    label: 'Шарыпово',
  },
  {
    region: 'Красноярский край',
    label: 'Шира',
  },
  {
    region: 'Красноярский край',
    label: 'Шушенское',
  },
  {
    region: 'Курганская обл.',
    label: 'Варгаши',
  },
  {
    region: 'Курганская обл.',
    label: 'Глядянское',
  },
  {
    region: 'Курганская обл.',
    label: 'Далматово',
  },
  {
    region: 'Курганская обл.',
    label: 'Каргаполье',
  },
  {
    region: 'Курганская обл.',
    label: 'Катайск',
  },
  {
    region: 'Курганская обл.',
    label: 'Кетово',
  },
  {
    region: 'Курганская обл.',
    label: 'Курган',
  },
  {
    region: 'Курганская обл.',
    label: 'Куртамыш',
  },
  {
    region: 'Курганская обл.',
    label: 'Лебяжье',
  },
  {
    region: 'Курганская обл.',
    label: 'Макушино',
  },
  {
    region: 'Курганская обл.',
    label: 'Мишкино',
  },
  {
    region: 'Курганская обл.',
    label: 'Мокроусово',
  },
  {
    region: 'Курганская обл.',
    label: 'Петухово',
  },
  {
    region: 'Курганская обл.',
    label: 'Половинное',
  },
  {
    region: 'Курганская обл.',
    label: 'Сафакулево',
  },
  {
    region: 'Курганская обл.',
    label: 'Целинное',
  },
  {
    region: 'Курганская обл.',
    label: 'Шадринск',
  },
  {
    region: 'Курганская обл.',
    label: 'Шатрово',
  },
  {
    region: 'Курганская обл.',
    label: 'Шумиха',
  },
  {
    region: 'Курганская обл.',
    label: 'Щучье',
  },
  {
    region: 'Курганская обл.',
    label: 'Юргамыш',
  },
  {
    region: 'Курская обл.',
    label: 'Альменево',
  },
  {
    region: 'Курская обл.',
    label: 'Белая',
  },
  {
    region: 'Курская обл.',
    label: 'Большое Солдатское',
  },
  {
    region: 'Курская обл.',
    label: 'Глушково',
  },
  {
    region: 'Курская обл.',
    label: 'Горшечное',
  },
  {
    region: 'Курская обл.',
    label: 'Дмитриев-Льговский',
  },
  {
    region: 'Курская обл.',
    label: 'Железногорск',
  },
  {
    region: 'Курская обл.',
    label: 'Золотухино',
  },
  {
    region: 'Курская обл.',
    label: 'Касторное',
  },
  {
    region: 'Курская обл.',
    label: 'Конышевка',
  },
  {
    region: 'Курская обл.',
    label: 'Коренево',
  },
  {
    region: 'Курская обл.',
    label: 'Курск',
  },
  {
    region: 'Курская обл.',
    label: 'Курчатов',
  },
  {
    region: 'Курская обл.',
    label: 'Кшенский',
  },
  {
    region: 'Курская обл.',
    label: 'Льгов',
  },
  {
    region: 'Курская обл.',
    label: 'Мантурово',
  },
  {
    region: 'Курская обл.',
    label: 'Медвенка',
  },
  {
    region: 'Курская обл.',
    label: 'Обоянь',
  },
  {
    region: 'Курская обл.',
    label: 'Поныри',
  },
  {
    region: 'Курская обл.',
    label: 'Пристень',
  },
  {
    region: 'Курская обл.',
    label: 'Прямицыно',
  },
  {
    region: 'Курская обл.',
    label: 'Рыльск',
  },
  {
    region: 'Курская обл.',
    label: 'Суджа',
  },
  {
    region: 'Курская обл.',
    label: 'Тим',
  },
  {
    region: 'Курская обл.',
    label: 'Фатеж',
  },
  {
    region: 'Курская обл.',
    label: 'Хомутовка',
  },
  {
    region: 'Курская обл.',
    label: 'Черемисиново',
  },
  {
    region: 'Курская обл.',
    label: 'Щигры',
  },
  {
    region: 'Липецкая обл.',
    label: 'Грязи',
  },
  {
    region: 'Липецкая обл.',
    label: 'Данхов',
  },
  {
    region: 'Липецкая обл.',
    label: 'Доброе',
  },
  {
    region: 'Липецкая обл.',
    label: 'Долгоруково',
  },
  {
    region: 'Липецкая обл.',
    label: 'Елец',
  },
  {
    region: 'Липецкая обл.',
    label: 'Задонск',
  },
  {
    region: 'Липецкая обл.',
    label: 'Измалково',
  },
  {
    region: 'Липецкая обл.',
    label: 'Казинка',
  },
  {
    region: 'Липецкая обл.',
    label: 'Лебедянь',
  },
  {
    region: 'Липецкая обл.',
    label: 'Лев Толстой',
  },
  {
    region: 'Липецкая обл.',
    label: 'Липецк',
  },
  {
    region: 'Липецкая обл.',
    label: 'Тербуны',
  },
  {
    region: 'Липецкая обл.',
    label: 'Усмань',
  },
  {
    region: 'Липецкая обл.',
    label: 'Хлевное',
  },
  {
    region: 'Липецкая обл.',
    label: 'Чаплыгин',
  },
  {
    region: 'Магаданская обл.',
    label: 'Анадырь',
  },
  {
    region: 'Магаданская обл.',
    label: 'Атка',
  },
  {
    region: 'Магаданская обл.',
    label: 'Балыгычан',
  },
  {
    region: 'Магаданская обл.',
    label: 'Беринговский',
  },
  {
    region: 'Магаданская обл.',
    label: 'Билибино',
  },
  {
    region: 'Магаданская обл.',
    label: 'Большевик',
  },
  {
    region: 'Магаданская обл.',
    label: 'Ванкарем',
  },
  {
    region: 'Магаданская обл.',
    label: 'Иульитин',
  },
  {
    region: 'Магаданская обл.',
    label: 'Кадыкчан',
  },
  {
    region: 'Магаданская обл.',
    label: 'Лаврентия',
  },
  {
    region: 'Магаданская обл.',
    label: 'Магадан',
  },
  {
    region: 'Магаданская обл.',
    label: 'Мыс Шмидта',
  },
  {
    region: 'Магаданская обл.',
    label: 'Ола',
  },
  {
    region: 'Магаданская обл.',
    label: 'Омонск',
  },
  {
    region: 'Магаданская обл.',
    label: 'Омсукчан',
  },
  {
    region: 'Магаданская обл.',
    label: 'Палатка',
  },
  {
    region: 'Магаданская обл.',
    label: 'Певек',
  },
  {
    region: 'Магаданская обл.',
    label: 'Провидения',
  },
  {
    region: 'Магаданская обл.',
    label: 'Сеймчан',
  },
  {
    region: 'Магаданская обл.',
    label: 'Синегорье',
  },
  {
    region: 'Магаданская обл.',
    label: 'Сусуман',
  },
  {
    region: 'Магаданская обл.',
    label: 'Усть-Белая',
  },
  {
    region: 'Магаданская обл.',
    label: 'Усть-Омчуг',
  },
  {
    region: 'Магаданская обл.',
    label: 'Эвенск',
  },
  {
    region: 'Магаданская обл.',
    label: 'Эгвекинот',
  },
  {
    region: 'Магаданская обл.',
    label: 'Ягодное',
  },
  {
    region: 'Марий Эл',
    label: 'Волжск',
  },
  {
    region: 'Марий Эл',
    label: 'Дубовский',
  },
  {
    region: 'Марий Эл',
    label: 'Звенигово',
  },
  {
    region: 'Марий Эл',
    label: 'Йошкар-Ола',
  },
  {
    region: 'Марий Эл',
    label: 'Килемары',
  },
  {
    region: 'Марий Эл',
    label: 'Козьмодемьянск',
  },
  {
    region: 'Марий Эл',
    label: 'Куженер',
  },
  {
    region: 'Марий Эл',
    label: 'Мари-Турек',
  },
  {
    region: 'Марий Эл',
    label: 'Медведево',
  },
  {
    region: 'Марий Эл',
    label: 'Морки',
  },
  {
    region: 'Марий Эл',
    label: 'Новый Торьял',
  },
  {
    region: 'Марий Эл',
    label: 'Оршанка',
  },
  {
    region: 'Марий Эл',
    label: 'Параньга',
  },
  {
    region: 'Марий Эл',
    label: 'Сернур',
  },
  {
    region: 'Марий Эл',
    label: 'Советский',
  },
  {
    region: 'Марий Эл',
    label: 'Юрино',
  },
  {
    region: 'Мордовия',
    label: 'Ардатов',
  },
  {
    region: 'Мордовия',
    label: 'Атюрьево',
  },
  {
    region: 'Мордовия',
    label: 'Атяшево',
  },
  {
    region: 'Мордовия',
    label: 'Большие Березники',
  },
  {
    region: 'Мордовия',
    label: 'Большое Игнатово',
  },
  {
    region: 'Мордовия',
    label: 'Выша',
  },
  {
    region: 'Мордовия',
    label: 'Ельники',
  },
  {
    region: 'Мордовия',
    label: 'Зубова Поляна',
  },
  {
    region: 'Мордовия',
    label: 'Инсар',
  },
  {
    region: 'Мордовия',
    label: 'Кадошкино',
  },
  {
    region: 'Мордовия',
    label: 'Кемля',
  },
  {
    region: 'Мордовия',
    label: 'Ковылкино',
  },
  {
    region: 'Мордовия',
    label: 'Комсомольский',
  },
  {
    region: 'Мордовия',
    label: 'Кочкурово',
  },
  {
    region: 'Мордовия',
    label: 'Краснослободск',
  },
  {
    region: 'Мордовия',
    label: 'Лямбирь',
  },
  {
    region: 'Мордовия',
    label: 'Ромоданово',
  },
  {
    region: 'Мордовия',
    label: 'Рузаевка',
  },
  {
    region: 'Мордовия',
    label: 'Саранск',
  },
  {
    region: 'Мордовия',
    label: 'Старое Шайгово',
  },
  {
    region: 'Мордовия',
    label: 'Темников',
  },
  {
    region: 'Мордовия',
    label: 'Теньгушево',
  },
  {
    region: 'Мордовия',
    label: 'Торбеево',
  },
  {
    region: 'Мордовия',
    label: 'Чамзинка',
  },
  {
    region: 'Мурманская обл.',
    label: 'Апатиты',
  },
  {
    region: 'Мурманская обл.',
    label: 'Африканда',
  },
  {
    region: 'Мурманская обл.',
    label: 'Верхнетуломский',
  },
  {
    region: 'Мурманская обл.',
    label: 'Заозерск',
  },
  {
    region: 'Мурманская обл.',
    label: 'Заполярный',
  },
  {
    region: 'Мурманская обл.',
    label: 'Зареченск',
  },
  {
    region: 'Мурманская обл.',
    label: 'Зашеек',
  },
  {
    region: 'Мурманская обл.',
    label: 'Зеленоборский',
  },
  {
    region: 'Мурманская обл.',
    label: 'Кандалакша',
  },
  {
    region: 'Мурманская обл.',
    label: 'Кильдинстрой',
  },
  {
    region: 'Мурманская обл.',
    label: 'Кировск',
  },
  {
    region: 'Мурманская обл.',
    label: 'Ковдор',
  },
  {
    region: 'Мурманская обл.',
    label: 'Кола',
  },
  {
    region: 'Мурманская обл.',
    label: 'Конда',
  },
  {
    region: 'Мурманская обл.',
    label: 'Мончегорск',
  },
  {
    region: 'Мурманская обл.',
    label: 'Мурманск',
  },
  {
    region: 'Мурманская обл.',
    label: 'Мурмаши',
  },
  {
    region: 'Мурманская обл.',
    label: 'Никель',
  },
  {
    region: 'Мурманская обл.',
    label: 'Оленегорск',
  },
  {
    region: 'Мурманская обл.',
    label: 'Полярные Зори',
  },
  {
    region: 'Мурманская обл.',
    label: 'Полярный',
  },
  {
    region: 'Мурманская обл.',
    label: 'Североморск',
  },
  {
    region: 'Мурманская обл.',
    label: 'Снежногорск',
  },
  {
    region: 'Мурманская обл.',
    label: 'Умба',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Ардатов',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Арзамас',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Арья',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Балахна',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Богородск',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Большереченск',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Большое Болдино',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Большое Козино',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Большое Мурашкино',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Большое Пикино',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Бор',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Бутурлино',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Вад',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Варнавино',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Васильсурск',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Вахтан',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Вача',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Велетьма',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Ветлуга',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Виля',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Вознесенское',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Володарск',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Воротынец',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Ворсма',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Воскресенское',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Выездное',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Выкса',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Гагино',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Гидроторф',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Горбатов',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Горбатовка',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Городец',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Горький',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Дальнее Константиново',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Дзержинск',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Дивеево',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Досчатое',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Заволжье',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Катунки',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Керженец',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Княгинино',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Ковернино',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Красные Баки',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Кстово',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Кулебаки',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Лукоянов',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Лысково',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Навашино',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Нижний Новгород',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Павлово',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Первомайск',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Перевоз',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Пильна',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Починки',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Саров',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Сергач',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Сеченово',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Сосновское',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Спасское',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Тонкино',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Тоншаево',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Уразовка',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Урень',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Чкаловск',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Шаранга',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Шатки',
  },
  {
    region: 'Нижегородская (Горьковская)',
    label: 'Шахунья',
  },
  {
    region: 'Новгородская обл.',
    label: 'Анциферово',
  },
  {
    region: 'Новгородская обл.',
    label: 'Батецкий',
  },
  {
    region: 'Новгородская обл.',
    label: 'Большая Вишера',
  },
  {
    region: 'Новгородская обл.',
    label: 'Боровичи',
  },
  {
    region: 'Новгородская обл.',
    label: 'Валдай',
  },
  {
    region: 'Новгородская обл.',
    label: 'Волот',
  },
  {
    region: 'Новгородская обл.',
    label: 'Деманск',
  },
  {
    region: 'Новгородская обл.',
    label: 'Зарубино',
  },
  {
    region: 'Новгородская обл.',
    label: 'Кресцы',
  },
  {
    region: 'Новгородская обл.',
    label: 'Любытино',
  },
  {
    region: 'Новгородская обл.',
    label: 'Малая Вишера',
  },
  {
    region: 'Новгородская обл.',
    label: 'Марево',
  },
  {
    region: 'Новгородская обл.',
    label: 'Мошенское',
  },
  {
    region: 'Новгородская обл.',
    label: 'Новгород',
  },
  {
    region: 'Новгородская обл.',
    label: 'Окуловка',
  },
  {
    region: 'Новгородская обл.',
    label: 'Парфино',
  },
  {
    region: 'Новгородская обл.',
    label: 'Пестово',
  },
  {
    region: 'Новгородская обл.',
    label: 'Поддорье',
  },
  {
    region: 'Новгородская обл.',
    label: 'Сольцы',
  },
  {
    region: 'Новгородская обл.',
    label: 'Старая Русса',
  },
  {
    region: 'Новгородская обл.',
    label: 'Хвойное',
  },
  {
    region: 'Новгородская обл.',
    label: 'Холм',
  },
  {
    region: 'Новгородская обл.',
    label: 'Чудово',
  },
  {
    region: 'Новгородская обл.',
    label: 'Шимск',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Баган',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Барабинск',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Бердск',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Биаза',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Болотное',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Венгерово',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Довольное',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Завьялово',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Искитим',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Карасук',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Каргат',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Колывань',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Краснозерское',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Крутиха',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Куйбышев',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Купино',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Кыштовка',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Маслянино',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Михайловский',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Мошково',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Новосибирск',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Ордынское',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Северное',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Сузун',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Татарск',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Тогучин',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Убинское',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Усть-Тарка',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Чаны',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Черепаново',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Чистоозерное',
  },
  {
    region: 'Новосибирская обл.',
    label: 'Чулым',
  },
  {
    region: 'Омская обл.',
    label: 'Береговой',
  },
  {
    region: 'Омская обл.',
    label: 'Большеречье',
  },
  {
    region: 'Омская обл.',
    label: 'Большие Уки',
  },
  {
    region: 'Омская обл.',
    label: 'Горьковское',
  },
  {
    region: 'Омская обл.',
    label: 'Знаменское',
  },
  {
    region: 'Омская обл.',
    label: 'Исилькуль',
  },
  {
    region: 'Омская обл.',
    label: 'Калачинск',
  },
  {
    region: 'Омская обл.',
    label: 'Колосовка',
  },
  {
    region: 'Омская обл.',
    label: 'Кормиловка',
  },
  {
    region: 'Омская обл.',
    label: 'Крутинка',
  },
  {
    region: 'Омская обл.',
    label: 'Любинский',
  },
  {
    region: 'Омская обл.',
    label: 'Марьяновка',
  },
  {
    region: 'Омская обл.',
    label: 'Муромцево',
  },
  {
    region: 'Омская обл.',
    label: 'Называевск',
  },
  {
    region: 'Омская обл.',
    label: 'Нижняя Омка',
  },
  {
    region: 'Омская обл.',
    label: 'Нововаршавка',
  },
  {
    region: 'Омская обл.',
    label: 'Одесское',
  },
  {
    region: 'Омская обл.',
    label: 'Оконешниково',
  },
  {
    region: 'Омская обл.',
    label: 'Омск',
  },
  {
    region: 'Омская обл.',
    label: 'Павлоградка',
  },
  {
    region: 'Омская обл.',
    label: 'Полтавка',
  },
  {
    region: 'Омская обл.',
    label: 'Русская Поляна',
  },
  {
    region: 'Омская обл.',
    label: 'Саргатское',
  },
  {
    region: 'Омская обл.',
    label: 'Седельниково',
  },
  {
    region: 'Омская обл.',
    label: 'Таврическое',
  },
  {
    region: 'Омская обл.',
    label: 'Тара',
  },
  {
    region: 'Омская обл.',
    label: 'Тевриз',
  },
  {
    region: 'Омская обл.',
    label: 'Тюкалинск',
  },
  {
    region: 'Омская обл.',
    label: 'Усть-Ишим',
  },
  {
    region: 'Омская обл.',
    label: 'Черлак',
  },
  {
    region: 'Омская обл.',
    label: 'Шербакуль',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Абдулино',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Адамовка',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Айдырлинский',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Акбулак',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Аккермановка',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Асекеево',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Беляевка',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Бугуруслан',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Бузулук',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Гай',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Грачевка',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Домбаровский',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Дубенский',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Илек',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Ириклинский',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Кувандык',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Курманаевка',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Матвеевка',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Медногорск',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Новоорск',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Новосергиевка',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Новотроицк',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Октябрьское',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Оренбург',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Орск',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Первомайский',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Переволоцкий',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Пономаревка',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Саракташ',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Светлый',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Северное',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Соль-Илецк',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Сорочинск',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Ташла',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Тоцкое',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Тюльган',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Шарлык',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Энергетик',
  },
  {
    region: 'Оренбургская обл.',
    label: 'Ясный',
  },
  {
    region: 'Орловская обл.',
    label: 'Болхов',
  },
  {
    region: 'Орловская обл.',
    label: 'Верховье',
  },
  {
    region: 'Орловская обл.',
    label: 'Глазуновка',
  },
  {
    region: 'Орловская обл.',
    label: 'Дмитровск-Орловский',
  },
  {
    region: 'Орловская обл.',
    label: 'Долгое',
  },
  {
    region: 'Орловская обл.',
    label: 'Залегощь',
  },
  {
    region: 'Орловская обл.',
    label: 'Змиевка',
  },
  {
    region: 'Орловская обл.',
    label: 'Знаменское',
  },
  {
    region: 'Орловская обл.',
    label: 'Колпны',
  },
  {
    region: 'Орловская обл.',
    label: 'Красная Заря',
  },
  {
    region: 'Орловская обл.',
    label: 'Кромы',
  },
  {
    region: 'Орловская обл.',
    label: 'Ливны',
  },
  {
    region: 'Орловская обл.',
    label: 'Малоархангельск',
  },
  {
    region: 'Орловская обл.',
    label: 'Мценск',
  },
  {
    region: 'Орловская обл.',
    label: 'Нарышкино',
  },
  {
    region: 'Орловская обл.',
    label: 'Новосиль',
  },
  {
    region: 'Орловская обл.',
    label: 'Орел',
  },
  {
    region: 'Орловская обл.',
    label: 'Покровское',
  },
  {
    region: 'Орловская обл.',
    label: 'Сосково',
  },
  {
    region: 'Орловская обл.',
    label: 'Тросна',
  },
  {
    region: 'Орловская обл.',
    label: 'Хомутово',
  },
  {
    region: 'Орловская обл.',
    label: 'Хотынец',
  },
  {
    region: 'Орловская обл.',
    label: 'Шаблыкино',
  },
  {
    region: 'Пензенская обл.',
    label: 'Башмаково',
  },
  {
    region: 'Пензенская обл.',
    label: 'Беднодемьяновск',
  },
  {
    region: 'Пензенская обл.',
    label: 'Беково',
  },
  {
    region: 'Пензенская обл.',
    label: 'Белинский',
  },
  {
    region: 'Пензенская обл.',
    label: 'Бессоновка',
  },
  {
    region: 'Пензенская обл.',
    label: 'Вадинск',
  },
  {
    region: 'Пензенская обл.',
    label: 'Верхозим',
  },
  {
    region: 'Пензенская обл.',
    label: 'Городище',
  },
  {
    region: 'Пензенская обл.',
    label: 'Евлашево',
  },
  {
    region: 'Пензенская обл.',
    label: 'Земетчино',
  },
  {
    region: 'Пензенская обл.',
    label: 'Золотаревка',
  },
  {
    region: 'Пензенская обл.',
    label: 'Исса',
  },
  {
    region: 'Пензенская обл.',
    label: 'Каменка',
  },
  {
    region: 'Пензенская обл.',
    label: 'Колышлей',
  },
  {
    region: 'Пензенская обл.',
    label: 'Кондоль',
  },
  {
    region: 'Пензенская обл.',
    label: 'Кузнецк',
  },
  {
    region: 'Пензенская обл.',
    label: 'Лопатино',
  },
  {
    region: 'Пензенская обл.',
    label: 'Малая Сердоба',
  },
  {
    region: 'Пензенская обл.',
    label: 'Мокшан',
  },
  {
    region: 'Пензенская обл.',
    label: 'Наровчат',
  },
  {
    region: 'Пензенская обл.',
    label: 'Неверкино',
  },
  {
    region: 'Пензенская обл.',
    label: 'Нижний Ломов',
  },
  {
    region: 'Пензенская обл.',
    label: 'Никольск',
  },
  {
    region: 'Пензенская обл.',
    label: 'Пачелма',
  },
  {
    region: 'Пензенская обл.',
    label: 'Пенза',
  },
  {
    region: 'Пензенская обл.',
    label: 'Русский Камешкир',
  },
  {
    region: 'Пензенская обл.',
    label: 'Сердобск',
  },
  {
    region: 'Пензенская обл.',
    label: 'Сосновоборск',
  },
  {
    region: 'Пензенская обл.',
    label: 'Сура',
  },
  {
    region: 'Пензенская обл.',
    label: 'Тамала',
  },
  {
    region: 'Пензенская обл.',
    label: 'Шемышейка',
  },
  {
    region: 'Пермская обл.',
    label: 'Барда',
  },
  {
    region: 'Пермская обл.',
    label: 'Березники',
  },
  {
    region: 'Пермская обл.',
    label: 'Большая Соснова',
  },
  {
    region: 'Пермская обл.',
    label: 'Верещагино',
  },
  {
    region: 'Пермская обл.',
    label: 'Гайны',
  },
  {
    region: 'Пермская обл.',
    label: 'Горнозаводск',
  },
  {
    region: 'Пермская обл.',
    label: 'Гремячинск',
  },
  {
    region: 'Пермская обл.',
    label: 'Губаха',
  },
  {
    region: 'Пермская обл.',
    label: 'Добрянка',
  },
  {
    region: 'Пермская обл.',
    label: 'Елово',
  },
  {
    region: 'Пермская обл.',
    label: 'Зюкайка',
  },
  {
    region: 'Пермская обл.',
    label: 'Ильинский',
  },
  {
    region: 'Пермская обл.',
    label: 'Карагай',
  },
  {
    region: 'Пермская обл.',
    label: 'Керчевский',
  },
  {
    region: 'Пермская обл.',
    label: 'Кизел',
  },
  {
    region: 'Пермская обл.',
    label: 'Коса',
  },
  {
    region: 'Пермская обл.',
    label: 'Кочево',
  },
  {
    region: 'Пермская обл.',
    label: 'Красновишерск',
  },
  {
    region: 'Пермская обл.',
    label: 'Краснокамск',
  },
  {
    region: 'Пермская обл.',
    label: 'Кудымкар',
  },
  {
    region: 'Пермская обл.',
    label: 'Куеда',
  },
  {
    region: 'Пермская обл.',
    label: 'Кунгур',
  },
  {
    region: 'Пермская обл.',
    label: 'Лысьва',
  },
  {
    region: 'Пермская обл.',
    label: 'Ныроб',
  },
  {
    region: 'Пермская обл.',
    label: 'Нытва',
  },
  {
    region: 'Пермская обл.',
    label: 'Октябрьский',
  },
  {
    region: 'Пермская обл.',
    label: 'Орда',
  },
  {
    region: 'Пермская обл.',
    label: 'Оса',
  },
  {
    region: 'Пермская обл.',
    label: 'Оханск',
  },
  {
    region: 'Пермская обл.',
    label: 'Очер',
  },
  {
    region: 'Пермская обл.',
    label: 'Пермь',
  },
  {
    region: 'Пермская обл.',
    label: 'Соликамск',
  },
  {
    region: 'Пермская обл.',
    label: 'Суксун',
  },
  {
    region: 'Пермская обл.',
    label: 'Уинское',
  },
  {
    region: 'Пермская обл.',
    label: 'Усолье',
  },
  {
    region: 'Пермская обл.',
    label: 'Усть-Кишерть',
  },
  {
    region: 'Пермская обл.',
    label: 'Чайковский',
  },
  {
    region: 'Пермская обл.',
    label: 'Частые',
  },
  {
    region: 'Пермская обл.',
    label: 'Чердынь',
  },
  {
    region: 'Пермская обл.',
    label: 'Чернореченский',
  },
  {
    region: 'Пермская обл.',
    label: 'Чернушка',
  },
  {
    region: 'Пермская обл.',
    label: 'Чусовой',
  },
  {
    region: 'Пермская обл.',
    label: 'Юрла',
  },
  {
    region: 'Пермская обл.',
    label: 'Юсьва',
  },
  {
    region: 'Приморский край',
    label: 'Анучино',
  },
  {
    region: 'Приморский край',
    label: 'Арсеньев',
  },
  {
    region: 'Приморский край',
    label: 'Артем',
  },
  {
    region: 'Приморский край',
    label: 'Артемовский',
  },
  {
    region: 'Приморский край',
    label: 'Большой Камень',
  },
  {
    region: 'Приморский край',
    label: 'Валентин',
  },
  {
    region: 'Приморский край',
    label: 'Владивосток',
  },
  {
    region: 'Приморский край',
    label: 'Высокогорск',
  },
  {
    region: 'Приморский край',
    label: 'Горные Ключи',
  },
  {
    region: 'Приморский край',
    label: 'Горный',
  },
  {
    region: 'Приморский край',
    label: 'Дальнегорск',
  },
  {
    region: 'Приморский край',
    label: 'Дальнереченск',
  },
  {
    region: 'Приморский край',
    label: 'Зарубино',
  },
  {
    region: 'Приморский край',
    label: 'Кавалерово',
  },
  {
    region: 'Приморский край',
    label: 'Каменка',
  },
  {
    region: 'Приморский край',
    label: 'Камень-Рыболов',
  },
  {
    region: 'Приморский край',
    label: 'Кировский',
  },
  {
    region: 'Приморский край',
    label: 'Лазо',
  },
  {
    region: 'Приморский край',
    label: 'Лесозаводск',
  },
  {
    region: 'Приморский край',
    label: 'Лучегорск',
  },
  {
    region: 'Приморский край',
    label: 'Михайловка',
  },
  {
    region: 'Приморский край',
    label: 'Находка',
  },
  {
    region: 'Приморский край',
    label: 'Новопокровка',
  },
  {
    region: 'Приморский край',
    label: 'Ольга',
  },
  {
    region: 'Приморский край',
    label: 'Партизанск',
  },
  {
    region: 'Приморский край',
    label: 'Пограничный',
  },
  {
    region: 'Приморский край',
    label: 'Покровка',
  },
  {
    region: 'Приморский край',
    label: 'Русский',
  },
  {
    region: 'Приморский край',
    label: 'Самарга',
  },
  {
    region: 'Приморский край',
    label: 'Славянка',
  },
  {
    region: 'Приморский край',
    label: 'Спасск-Дальний',
  },
  {
    region: 'Приморский край',
    label: 'Терней',
  },
  {
    region: 'Приморский край',
    label: 'Уссурийск',
  },
  {
    region: 'Приморский край',
    label: 'Фокино',
  },
  {
    region: 'Приморский край',
    label: 'Хасан',
  },
  {
    region: 'Приморский край',
    label: 'Хороль',
  },
  {
    region: 'Приморский край',
    label: 'Черниговка',
  },
  {
    region: 'Приморский край',
    label: 'Чугуевка',
  },
  {
    region: 'Приморский край',
    label: 'Яковлевка',
  },
  {
    region: 'Псковская обл.',
    label: 'Бежаницы',
  },
  {
    region: 'Псковская обл.',
    label: 'Великие Луки',
  },
  {
    region: 'Псковская обл.',
    label: 'Гдов',
  },
  {
    region: 'Псковская обл.',
    label: 'Дедовичи',
  },
  {
    region: 'Псковская обл.',
    label: 'Дно',
  },
  {
    region: 'Псковская обл.',
    label: 'Заплюсье',
  },
  {
    region: 'Псковская обл.',
    label: 'Идрица',
  },
  {
    region: 'Псковская обл.',
    label: 'Красногородское',
  },
  {
    region: 'Псковская обл.',
    label: 'Кунья',
  },
  {
    region: 'Псковская обл.',
    label: 'Локня',
  },
  {
    region: 'Псковская обл.',
    label: 'Невель',
  },
  {
    region: 'Псковская обл.',
    label: 'Новоржев',
  },
  {
    region: 'Псковская обл.',
    label: 'Новосокольники',
  },
  {
    region: 'Псковская обл.',
    label: 'Опочка',
  },
  {
    region: 'Псковская обл.',
    label: 'Остров',
  },
  {
    region: 'Псковская обл.',
    label: 'Палкино',
  },
  {
    region: 'Псковская обл.',
    label: 'Печоры',
  },
  {
    region: 'Псковская обл.',
    label: 'Плюсса',
  },
  {
    region: 'Псковская обл.',
    label: 'Порхов',
  },
  {
    region: 'Псковская обл.',
    label: 'Псков',
  },
  {
    region: 'Псковская обл.',
    label: 'Пустошка',
  },
  {
    region: 'Псковская обл.',
    label: 'Пушкинские Горы',
  },
  {
    region: 'Псковская обл.',
    label: 'Пыталово',
  },
  {
    region: 'Псковская обл.',
    label: 'Себеж',
  },
  {
    region: 'Псковская обл.',
    label: 'Струги-Красные',
  },
  {
    region: 'Псковская обл.',
    label: 'Усвяты',
  },
  {
    region: 'Ростовская обл.',
    label: 'Азов',
  },
  {
    region: 'Ростовская обл.',
    label: 'Аксай',
  },
  {
    region: 'Ростовская обл.',
    label: 'Алмазный',
  },
  {
    region: 'Ростовская обл.',
    label: 'Аютинск',
  },
  {
    region: 'Ростовская обл.',
    label: 'Багаевский',
  },
  {
    region: 'Ростовская обл.',
    label: 'Батайск',
  },
  {
    region: 'Ростовская обл.',
    label: 'Белая Калитва',
  },
  {
    region: 'Ростовская обл.',
    label: 'Боковская',
  },
  {
    region: 'Ростовская обл.',
    label: 'Большая Мартыновка',
  },
  {
    region: 'Ростовская обл.',
    label: 'Вешенская',
  },
  {
    region: 'Ростовская обл.',
    label: 'Волгодонск',
  },
  {
    region: 'Ростовская обл.',
    label: 'Восход',
  },
  {
    region: 'Ростовская обл.',
    label: 'Гигант',
  },
  {
    region: 'Ростовская обл.',
    label: 'Горняцкий',
  },
  {
    region: 'Ростовская обл.',
    label: 'Гуково',
  },
  {
    region: 'Ростовская обл.',
    label: 'Донецк',
  },
  {
    region: 'Ростовская обл.',
    label: 'Донской',
  },
  {
    region: 'Ростовская обл.',
    label: 'Дубовское',
  },
  {
    region: 'Ростовская обл.',
    label: 'Егорлыкская',
  },
  {
    region: 'Ростовская обл.',
    label: 'Жирнов',
  },
  {
    region: 'Ростовская обл.',
    label: 'Заветное',
  },
  {
    region: 'Ростовская обл.',
    label: 'Заводской',
  },
  {
    region: 'Ростовская обл.',
    label: 'Зверево',
  },
  {
    region: 'Ростовская обл.',
    label: 'Зерноград',
  },
  {
    region: 'Ростовская обл.',
    label: 'Зимовники',
  },
  {
    region: 'Ростовская обл.',
    label: 'Кагальницкая',
  },
  {
    region: 'Ростовская обл.',
    label: 'Казанская',
  },
  {
    region: 'Ростовская обл.',
    label: 'Каменоломни',
  },
  {
    region: 'Ростовская обл.',
    label: 'Каменск-Шахтинский',
  },
  {
    region: 'Ростовская обл.',
    label: 'Кашары',
  },
  {
    region: 'Ростовская обл.',
    label: 'Коксовый',
  },
  {
    region: 'Ростовская обл.',
    label: 'Константиновск',
  },
  {
    region: 'Ростовская обл.',
    label: 'Красный Сулин',
  },
  {
    region: 'Ростовская обл.',
    label: 'Куйбышево',
  },
  {
    region: 'Ростовская обл.',
    label: 'Матвеев Курган',
  },
  {
    region: 'Ростовская обл.',
    label: 'Мигулинская',
  },
  {
    region: 'Ростовская обл.',
    label: 'Миллерово',
  },
  {
    region: 'Ростовская обл.',
    label: 'Милютинская',
  },
  {
    region: 'Ростовская обл.',
    label: 'Морозовск',
  },
  {
    region: 'Ростовская обл.',
    label: 'Новочеркасск',
  },
  {
    region: 'Ростовская обл.',
    label: 'Новошахтинск',
  },
  {
    region: 'Ростовская обл.',
    label: 'Обливская',
  },
  {
    region: 'Ростовская обл.',
    label: 'Орловский',
  },
  {
    region: 'Ростовская обл.',
    label: 'Песчанокопское',
  },
  {
    region: 'Ростовская обл.',
    label: 'Покровское',
  },
  {
    region: 'Ростовская обл.',
    label: 'Пролетарск',
  },
  {
    region: 'Ростовская обл.',
    label: 'Ремонтное',
  },
  {
    region: 'Ростовская обл.',
    label: 'Родионово-Несветайская',
  },
  {
    region: 'Ростовская обл.',
    label: 'Ростов-на-Дону',
  },
  {
    region: 'Ростовская обл.',
    label: 'Сальск',
  },
  {
    region: 'Ростовская обл.',
    label: 'Семикаракорск',
  },
  {
    region: 'Ростовская обл.',
    label: 'Таганрог',
  },
  {
    region: 'Ростовская обл.',
    label: 'Тарасовский',
  },
  {
    region: 'Ростовская обл.',
    label: 'Тацинский',
  },
  {
    region: 'Ростовская обл.',
    label: 'Усть-Донецкий',
  },
  {
    region: 'Ростовская обл.',
    label: 'Целина',
  },
  {
    region: 'Ростовская обл.',
    label: 'Цимлянск',
  },
  {
    region: 'Ростовская обл.',
    label: 'Чалтырь',
  },
  {
    region: 'Ростовская обл.',
    label: 'Чертково',
  },
  {
    region: 'Ростовская обл.',
    label: 'Шахты',
  },
  {
    region: 'Ростовская обл.',
    label: 'Шолоховский',
  },
  {
    region: 'Рязанская обл.',
    label: 'Александро-Невский',
  },
  {
    region: 'Рязанская обл.',
    label: 'Горняк',
  },
  {
    region: 'Рязанская обл.',
    label: 'Гусь Железный',
  },
  {
    region: 'Рязанская обл.',
    label: 'Елатьма',
  },
  {
    region: 'Рязанская обл.',
    label: 'Ермишь',
  },
  {
    region: 'Рязанская обл.',
    label: 'Заречный',
  },
  {
    region: 'Рязанская обл.',
    label: 'Захарово',
  },
  {
    region: 'Рязанская обл.',
    label: 'Кадом',
  },
  {
    region: 'Рязанская обл.',
    label: 'Касимов',
  },
  {
    region: 'Рязанская обл.',
    label: 'Кораблино',
  },
  {
    region: 'Рязанская обл.',
    label: 'Милославское',
  },
  {
    region: 'Рязанская обл.',
    label: 'Михайлов',
  },
  {
    region: 'Рязанская обл.',
    label: 'Пителино',
  },
  {
    region: 'Рязанская обл.',
    label: 'Пронск',
  },
  {
    region: 'Рязанская обл.',
    label: 'Путятино',
  },
  {
    region: 'Рязанская обл.',
    label: 'Рыбное',
  },
  {
    region: 'Рязанская обл.',
    label: 'Ряжск',
  },
  {
    region: 'Рязанская обл.',
    label: 'Рязань',
  },
  {
    region: 'Рязанская обл.',
    label: 'Сапожок',
  },
  {
    region: 'Рязанская обл.',
    label: 'Сараи',
  },
  {
    region: 'Рязанская обл.',
    label: 'Сасово',
  },
  {
    region: 'Рязанская обл.',
    label: 'Скопин',
  },
  {
    region: 'Рязанская обл.',
    label: 'Спас-Клепики',
  },
  {
    region: 'Рязанская обл.',
    label: 'Спасск-Рязанский',
  },
  {
    region: 'Рязанская обл.',
    label: 'Старожилово',
  },
  {
    region: 'Рязанская обл.',
    label: 'Ухолово',
  },
  {
    region: 'Рязанская обл.',
    label: 'Чучково',
  },
  {
    region: 'Рязанская обл.',
    label: 'Шацк',
  },
  {
    region: 'Рязанская обл.',
    label: 'Шилово',
  },
  {
    region: 'Самарская обл.',
    label: 'Алексеевка',
  },
  {
    region: 'Самарская обл.',
    label: 'Безенчук',
  },
  {
    region: 'Самарская обл.',
    label: 'Богатое',
  },
  {
    region: 'Самарская обл.',
    label: 'Богатырь',
  },
  {
    region: 'Самарская обл.',
    label: 'Большая Глущица',
  },
  {
    region: 'Самарская обл.',
    label: 'Большая Черниговка',
  },
  {
    region: 'Самарская обл.',
    label: 'Борское',
  },
  {
    region: 'Самарская обл.',
    label: 'Волжский',
  },
  {
    region: 'Самарская обл.',
    label: 'Жигулевск',
  },
  {
    region: 'Самарская обл.',
    label: 'Зольное',
  },
  {
    region: 'Самарская обл.',
    label: 'Исаклы',
  },
  {
    region: 'Самарская обл.',
    label: 'Камышла',
  },
  {
    region: 'Самарская обл.',
    label: 'Кинель',
  },
  {
    region: 'Самарская обл.',
    label: 'Кинель-Черкасы',
  },
  {
    region: 'Самарская обл.',
    label: 'Клявлино',
  },
  {
    region: 'Самарская обл.',
    label: 'Кошки',
  },
  {
    region: 'Самарская обл.',
    label: 'Красноармейское',
  },
  {
    region: 'Самарская обл.',
    label: 'Красный Яр',
  },
  {
    region: 'Самарская обл.',
    label: 'Куйбышев',
  },
  {
    region: 'Самарская обл.',
    label: 'Нефтегорск',
  },
  {
    region: 'Самарская обл.',
    label: 'Новокуйбышевск',
  },
  {
    region: 'Самарская обл.',
    label: 'Октябрьск',
  },
  {
    region: 'Самарская обл.',
    label: 'Отрадный',
  },
  {
    region: 'Самарская обл.',
    label: 'Пестравка',
  },
  {
    region: 'Самарская обл.',
    label: 'Похвистнево',
  },
  {
    region: 'Самарская обл.',
    label: 'Приволжье',
  },
  {
    region: 'Самарская обл.',
    label: 'Самара',
  },
  {
    region: 'Самарская обл.',
    label: 'Сургут (Самарская обл.)',
  },
  {
    region: 'Самарская обл.',
    label: 'Сызрань',
  },
  {
    region: 'Самарская обл.',
    label: 'Тольятти',
  },
  {
    region: 'Самарская обл.',
    label: 'Хворостянка',
  },
  {
    region: 'Самарская обл.',
    label: 'Чапаевск',
  },
  {
    region: 'Самарская обл.',
    label: 'Челно-Вершины',
  },
  {
    region: 'Самарская обл.',
    label: 'Шентала',
  },
  {
    region: 'Самарская обл.',
    label: 'Шигоны',
  },
  {
    region: 'Саратовская обл.',
    label: 'Александров Гай',
  },
  {
    region: 'Саратовская обл.',
    label: 'Аркадак',
  },
  {
    region: 'Саратовская обл.',
    label: 'Аткарск',
  },
  {
    region: 'Саратовская обл.',
    label: 'Базарный Карабулак',
  },
  {
    region: 'Саратовская обл.',
    label: 'Балаково',
  },
  {
    region: 'Саратовская обл.',
    label: 'Балашов',
  },
  {
    region: 'Саратовская обл.',
    label: 'Балтай',
  },
  {
    region: 'Саратовская обл.',
    label: 'Возрождение',
  },
  {
    region: 'Саратовская обл.',
    label: 'Вольск',
  },
  {
    region: 'Саратовская обл.',
    label: 'Воскресенское',
  },
  {
    region: 'Саратовская обл.',
    label: 'Горный',
  },
  {
    region: 'Саратовская обл.',
    label: 'Дергачи',
  },
  {
    region: 'Саратовская обл.',
    label: 'Духовницкое',
  },
  {
    region: 'Саратовская обл.',
    label: 'Екатериновка',
  },
  {
    region: 'Саратовская обл.',
    label: 'Ершов',
  },
  {
    region: 'Саратовская обл.',
    label: 'Заречный',
  },
  {
    region: 'Саратовская обл.',
    label: 'Ивантеевка',
  },
  {
    region: 'Саратовская обл.',
    label: 'Калининск',
  },
  {
    region: 'Саратовская обл.',
    label: 'Каменский',
  },
  {
    region: 'Саратовская обл.',
    label: 'Красноармейск',
  },
  {
    region: 'Саратовская обл.',
    label: 'Красный Кут',
  },
  {
    region: 'Саратовская обл.',
    label: 'Лысые Горы',
  },
  {
    region: 'Саратовская обл.',
    label: 'Маркс',
  },
  {
    region: 'Саратовская обл.',
    label: 'Мокроус',
  },
  {
    region: 'Саратовская обл.',
    label: 'Новоузенск',
  },
  {
    region: 'Саратовская обл.',
    label: 'Новые Бурасы',
  },
  {
    region: 'Саратовская обл.',
    label: 'Озинки',
  },
  {
    region: 'Саратовская обл.',
    label: 'Перелюб',
  },
  {
    region: 'Саратовская обл.',
    label: 'Петровск',
  },
  {
    region: 'Саратовская обл.',
    label: 'Питерка',
  },
  {
    region: 'Саратовская обл.',
    label: 'Пугачев',
  },
  {
    region: 'Саратовская обл.',
    label: 'Ровное',
  },
  {
    region: 'Саратовская обл.',
    label: 'Романовка',
  },
  {
    region: 'Саратовская обл.',
    label: 'Ртищево',
  },
  {
    region: 'Саратовская обл.',
    label: 'Самойловка',
  },
  {
    region: 'Саратовская обл.',
    label: 'Саратов',
  },
  {
    region: 'Саратовская обл.',
    label: 'Степное',
  },
  {
    region: 'Саратовская обл.',
    label: 'Татищево',
  },
  {
    region: 'Саратовская обл.',
    label: 'Турки',
  },
  {
    region: 'Саратовская обл.',
    label: 'Хвалынск',
  },
  {
    region: 'Саратовская обл.',
    label: 'Энгельс',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Абый',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Алдан',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Амга',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Батагай',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Бердигестях',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Беркакит',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Бестях',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Борогонцы',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Верхневилюйск',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Верхнеколымск',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Верхоянск',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Вилюйск',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Витим',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Власово',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Жиганск',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Зырянка',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Кангалассы',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Канкунский',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Ленск',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Майя',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Менкеря',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Мирный',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Нерюнгри',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Нычалах',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Нюрба',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Олекминск',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Покровск',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Сангар',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Саскылах',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Среднеколымск',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Сунтар',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Тикси',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Усть-Мая',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Усть-Нера',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Хандыга',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Хонуу',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Черский',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Чокурдах',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Чурапча',
  },
  {
    region: 'Саха (Якутия)',
    label: 'Якутск',
  },
  {
    region: 'Сахалин',
    label: 'Александровск-Сахалинский',
  },
  {
    region: 'Сахалин',
    label: 'Анбэцу',
  },
  {
    region: 'Сахалин',
    label: 'Анива',
  },
  {
    region: 'Сахалин',
    label: 'Бошняково',
  },
  {
    region: 'Сахалин',
    label: 'Быков',
  },
  {
    region: 'Сахалин',
    label: 'Вахрушев',
  },
  {
    region: 'Сахалин',
    label: 'Взморье',
  },
  {
    region: 'Сахалин',
    label: 'Гастелло',
  },
  {
    region: 'Сахалин',
    label: 'Горнозаводск',
  },
  {
    region: 'Сахалин',
    label: 'Долинск',
  },
  {
    region: 'Сахалин',
    label: 'Ильинский',
  },
  {
    region: 'Сахалин',
    label: 'Катангли',
  },
  {
    region: 'Сахалин',
    label: 'Корсаков',
  },
  {
    region: 'Сахалин',
    label: 'Курильск',
  },
  {
    region: 'Сахалин',
    label: 'Макаров',
  },
  {
    region: 'Сахалин',
    label: 'Невельск',
  },
  {
    region: 'Сахалин',
    label: 'Ноглики',
  },
  {
    region: 'Сахалин',
    label: 'Оха',
  },
  {
    region: 'Сахалин',
    label: 'Поронайск',
  },
  {
    region: 'Сахалин',
    label: 'Северо-Курильск',
  },
  {
    region: 'Сахалин',
    label: 'Смирных',
  },
  {
    region: 'Сахалин',
    label: 'Томари',
  },
  {
    region: 'Сахалин',
    label: 'Тымовское',
  },
  {
    region: 'Сахалин',
    label: 'Углегорск',
  },
  {
    region: 'Сахалин',
    label: 'Холмск',
  },
  {
    region: 'Сахалин',
    label: 'Шахтерск',
  },
  {
    region: 'Сахалин',
    label: 'Южно-Курильск',
  },
  {
    region: 'Сахалин',
    label: 'Южно-Сахалинск',
  },
  {
    region: 'Свердловская обл.',
    label: 'Алапаевск',
  },
  {
    region: 'Свердловская обл.',
    label: 'Алтынай',
  },
  {
    region: 'Свердловская обл.',
    label: 'Арамиль',
  },
  {
    region: 'Свердловская обл.',
    label: 'Артемовский',
  },
  {
    region: 'Свердловская обл.',
    label: 'Арти',
  },
  {
    region: 'Свердловская обл.',
    label: 'Асбест',
  },
  {
    region: 'Свердловская обл.',
    label: 'Ачит',
  },
  {
    region: 'Свердловская обл.',
    label: 'Байкалово',
  },
  {
    region: 'Свердловская обл.',
    label: 'Басьяновский',
  },
  {
    region: 'Свердловская обл.',
    label: 'Белоярский',
  },
  {
    region: 'Свердловская обл.',
    label: 'Березовский',
  },
  {
    region: 'Свердловская обл.',
    label: 'Богданович',
  },
  {
    region: 'Свердловская обл.',
    label: 'Буланаш',
  },
  {
    region: 'Свердловская обл.',
    label: 'Верхний Тагил',
  },
  {
    region: 'Свердловская обл.',
    label: 'Верхняя Пышма',
  },
  {
    region: 'Свердловская обл.',
    label: 'Верхняя Салда',
  },
  {
    region: 'Свердловская обл.',
    label: 'Верхняя Синячиха',
  },
  {
    region: 'Свердловская обл.',
    label: 'Верхняя Сысерть',
  },
  {
    region: 'Свердловская обл.',
    label: 'Верхняя Тура',
  },
  {
    region: 'Свердловская обл.',
    label: 'Верхотурье',
  },
  {
    region: 'Свердловская обл.',
    label: 'Висим',
  },
  {
    region: 'Свердловская обл.',
    label: 'Волчанск',
  },
  {
    region: 'Свердловская обл.',
    label: 'Воронцовка',
  },
  {
    region: 'Свердловская обл.',
    label: 'Гари',
  },
  {
    region: 'Свердловская обл.',
    label: 'Дегтярск',
  },
  {
    region: 'Свердловская обл.',
    label: 'Екатеринбург',
  },
  {
    region: 'Свердловская обл.',
    label: 'Ертарский',
  },
  {
    region: 'Свердловская обл.',
    label: 'Заводоуспенское',
  },
  {
    region: 'Свердловская обл.',
    label: 'Зыряновский',
  },
  {
    region: 'Свердловская обл.',
    label: 'Зюзельский',
  },
  {
    region: 'Свердловская обл.',
    label: 'Ивдель',
  },
  {
    region: 'Свердловская обл.',
    label: 'Изумруд',
  },
  {
    region: 'Свердловская обл.',
    label: 'Ирбит',
  },
  {
    region: 'Свердловская обл.',
    label: 'Ис',
  },
  {
    region: 'Свердловская обл.',
    label: 'Каменск-Уральский',
  },
  {
    region: 'Свердловская обл.',
    label: 'Камышлов',
  },
  {
    region: 'Свердловская обл.',
    label: 'Карпинск',
  },
  {
    region: 'Свердловская обл.',
    label: 'Карпунинский',
  },
  {
    region: 'Свердловская обл.',
    label: 'Качканар',
  },
  {
    region: 'Свердловская обл.',
    label: 'Кировград',
  },
  {
    region: 'Свердловская обл.',
    label: 'Краснотурьинск',
  },
  {
    region: 'Свердловская обл.',
    label: 'Красноуральск',
  },
  {
    region: 'Свердловская обл.',
    label: 'Красноуфимск',
  },
  {
    region: 'Свердловская обл.',
    label: 'Кушва',
  },
  {
    region: 'Свердловская обл.',
    label: 'Лесной',
  },
  {
    region: 'Свердловская обл.',
    label: 'Михайловск',
  },
  {
    region: 'Свердловская обл.',
    label: 'Невьянск',
  },
  {
    region: 'Свердловская обл.',
    label: 'Нижние Серги',
  },
  {
    region: 'Свердловская обл.',
    label: 'Нижний Тагил',
  },
  {
    region: 'Свердловская обл.',
    label: 'Нижняя Салда',
  },
  {
    region: 'Свердловская обл.',
    label: 'Нижняя Тура',
  },
  {
    region: 'Свердловская обл.',
    label: 'Новая Ляля',
  },
  {
    region: 'Свердловская обл.',
    label: 'Новоуральск',
  },
  {
    region: 'Свердловская обл.',
    label: 'Оус',
  },
  {
    region: 'Свердловская обл.',
    label: 'Первоуральск',
  },
  {
    region: 'Свердловская обл.',
    label: 'Полевской',
  },
  {
    region: 'Свердловская обл.',
    label: 'Пышма',
  },
  {
    region: 'Свердловская обл.',
    label: 'Ревда',
  },
  {
    region: 'Свердловская обл.',
    label: 'Реж',
  },
  {
    region: 'Свердловская обл.',
    label: 'Свердловск',
  },
  {
    region: 'Свердловская обл.',
    label: 'Североуральск',
  },
  {
    region: 'Свердловская обл.',
    label: 'Серов',
  },
  {
    region: 'Свердловская обл.',
    label: 'Сосьва',
  },
  {
    region: 'Свердловская обл.',
    label: 'Среднеуральск',
  },
  {
    region: 'Свердловская обл.',
    label: 'Сухой Лог',
  },
  {
    region: 'Свердловская обл.',
    label: 'Сысерть',
  },
  {
    region: 'Свердловская обл.',
    label: 'Таборы',
  },
  {
    region: 'Свердловская обл.',
    label: 'Тавда',
  },
  {
    region: 'Свердловская обл.',
    label: 'Талица',
  },
  {
    region: 'Свердловская обл.',
    label: 'Тугулым',
  },
  {
    region: 'Свердловская обл.',
    label: 'Туринск',
  },
  {
    region: 'Свердловская обл.',
    label: 'Туринская Слобода',
  },
  {
    region: 'Северная Осетия',
    label: 'Алагир',
  },
  {
    region: 'Северная Осетия',
    label: 'Ардон',
  },
  {
    region: 'Северная Осетия',
    label: 'Беслан',
  },
  {
    region: 'Северная Осетия',
    label: 'Бурон',
  },
  {
    region: 'Северная Осетия',
    label: 'Владикавказ',
  },
  {
    region: 'Северная Осетия',
    label: 'Дигора',
  },
  {
    region: 'Северная Осетия',
    label: 'Моздок',
  },
  {
    region: 'Северная Осетия',
    label: 'Орджоникидзе',
  },
  {
    region: 'Северная Осетия',
    label: 'Чикола',
  },
  {
    region: 'Смоленская обл.',
    label: 'Велиж',
  },
  {
    region: 'Смоленская обл.',
    label: 'Верхнеднепровский',
  },
  {
    region: 'Смоленская обл.',
    label: 'Ворга',
  },
  {
    region: 'Смоленская обл.',
    label: 'Вязьма',
  },
  {
    region: 'Смоленская обл.',
    label: 'Гагарин',
  },
  {
    region: 'Смоленская обл.',
    label: 'Глинка',
  },
  {
    region: 'Смоленская обл.',
    label: 'Голынки',
  },
  {
    region: 'Смоленская обл.',
    label: 'Демидов',
  },
  {
    region: 'Смоленская обл.',
    label: 'Десногорск',
  },
  {
    region: 'Смоленская обл.',
    label: 'Дорогобуж',
  },
  {
    region: 'Смоленская обл.',
    label: 'Духовщина',
  },
  {
    region: 'Смоленская обл.',
    label: 'Екимовичи',
  },
  {
    region: 'Смоленская обл.',
    label: 'Ельня',
  },
  {
    region: 'Смоленская обл.',
    label: 'Ершичи',
  },
  {
    region: 'Смоленская обл.',
    label: 'Издешково',
  },
  {
    region: 'Смоленская обл.',
    label: 'Кардымово',
  },
  {
    region: 'Смоленская обл.',
    label: 'Красный',
  },
  {
    region: 'Смоленская обл.',
    label: 'Монастырщина',
  },
  {
    region: 'Смоленская обл.',
    label: 'Новодугино',
  },
  {
    region: 'Смоленская обл.',
    label: 'Починок',
  },
  {
    region: 'Смоленская обл.',
    label: 'Рославль',
  },
  {
    region: 'Смоленская обл.',
    label: 'Рудня',
  },
  {
    region: 'Смоленская обл.',
    label: 'Сафоново',
  },
  {
    region: 'Смоленская обл.',
    label: 'Смоленск',
  },
  {
    region: 'Смоленская обл.',
    label: 'Сычевка',
  },
  {
    region: 'Смоленская обл.',
    label: 'Угра',
  },
  {
    region: 'Смоленская обл.',
    label: 'Хиславичи',
  },
  {
    region: 'Смоленская обл.',
    label: 'Холм-Жирковский',
  },
  {
    region: 'Смоленская обл.',
    label: 'Шумячи',
  },
  {
    region: 'Смоленская обл.',
    label: 'Ярцево',
  },
  {
    region: 'Ставропольский край',
    label: 'Александровское',
  },
  {
    region: 'Ставропольский край',
    label: 'Арзгир',
  },
  {
    region: 'Ставропольский край',
    label: 'Благодарный',
  },
  {
    region: 'Ставропольский край',
    label: 'Буденновск',
  },
  {
    region: 'Ставропольский край',
    label: 'Георгиевск',
  },
  {
    region: 'Ставропольский край',
    label: 'Дивное',
  },
  {
    region: 'Ставропольский край',
    label: 'Домбай',
  },
  {
    region: 'Ставропольский край',
    label: 'Донское',
  },
  {
    region: 'Ставропольский край',
    label: 'Ессентуки',
  },
  {
    region: 'Ставропольский край',
    label: 'Железноводск',
  },
  {
    region: 'Ставропольский край',
    label: 'Зеленокумск',
  },
  {
    region: 'Ставропольский край',
    label: 'Изобильный',
  },
  {
    region: 'Ставропольский край',
    label: 'Иноземцево',
  },
  {
    region: 'Ставропольский край',
    label: 'Ипатово',
  },
  {
    region: 'Ставропольский край',
    label: 'Карачаевск',
  },
  {
    region: 'Ставропольский край',
    label: 'Кисловодск',
  },
  {
    region: 'Ставропольский край',
    label: 'Кочубеевское',
  },
  {
    region: 'Ставропольский край',
    label: 'Красногвардейское',
  },
  {
    region: 'Ставропольский край',
    label: 'Курсавка',
  },
  {
    region: 'Ставропольский край',
    label: 'Левокумское',
  },
  {
    region: 'Ставропольский край',
    label: 'Минеральные Воды',
  },
  {
    region: 'Ставропольский край',
    label: 'Невинномысск',
  },
  {
    region: 'Ставропольский край',
    label: 'Нефтекумск',
  },
  {
    region: 'Ставропольский край',
    label: 'Новоалександровск',
  },
  {
    region: 'Ставропольский край',
    label: 'Новоалександровская',
  },
  {
    region: 'Ставропольский край',
    label: 'Новопавловск',
  },
  {
    region: 'Ставропольский край',
    label: 'Новоселицкое',
  },
  {
    region: 'Ставропольский край',
    label: 'Преградная',
  },
  {
    region: 'Ставропольский край',
    label: 'Пятигорск',
  },
  {
    region: 'Ставропольский край',
    label: 'Светлоград',
  },
  {
    region: 'Ставропольский край',
    label: 'Солнечнодольск',
  },
  {
    region: 'Ставропольский край',
    label: 'Ставрополь',
  },
  {
    region: 'Ставропольский край',
    label: 'Степное',
  },
  {
    region: 'Ставропольский край',
    label: 'Теберда',
  },
  {
    region: 'Ставропольский край',
    label: 'Усть-Джегута',
  },
  {
    region: 'Ставропольский край',
    label: 'Хабез',
  },
  {
    region: 'Ставропольский край',
    label: 'Черкесск',
  },
  {
    region: 'Тамбовская обл.',
    label: 'Бондари',
  },
  {
    region: 'Тамбовская обл.',
    label: 'Гавриловка Вторая',
  },
  {
    region: 'Тамбовская обл.',
    label: 'Жердевка',
  },
  {
    region: 'Тамбовская обл.',
    label: 'Знаменка',
  },
  {
    region: 'Тамбовская обл.',
    label: 'Инжавино',
  },
  {
    region: 'Тамбовская обл.',
    label: 'Кирсанов',
  },
  {
    region: 'Тамбовская обл.',
    label: 'Котовск',
  },
  {
    region: 'Тамбовская обл.',
    label: 'Мичуринск',
  },
  {
    region: 'Тамбовская обл.',
    label: 'Мордово',
  },
  {
    region: 'Тамбовская обл.',
    label: 'Моршанск',
  },
  {
    region: 'Тамбовская обл.',
    label: 'Мучкапский',
  },
  {
    region: 'Тамбовская обл.',
    label: 'Первомайский',
  },
  {
    region: 'Тамбовская обл.',
    label: 'Петровское',
  },
  {
    region: 'Тамбовская обл.',
    label: 'Пичаево',
  },
  {
    region: 'Тамбовская обл.',
    label: 'Рассказово',
  },
  {
    region: 'Тамбовская обл.',
    label: 'Ржакса',
  },
  {
    region: 'Тамбовская обл.',
    label: 'Староюрьево',
  },
  {
    region: 'Тамбовская обл.',
    label: 'Тамбов',
  },
  {
    region: 'Тамбовская обл.',
    label: 'Токаревка',
  },
  {
    region: 'Тамбовская обл.',
    label: 'Уварово',
  },
  {
    region: 'Тамбовская обл.',
    label: 'Умет',
  },
  {
    region: 'Татарстан',
    label: 'Агрыз',
  },
  {
    region: 'Татарстан',
    label: 'Азнакаево',
  },
  {
    region: 'Татарстан',
    label: 'Аксубаево',
  },
  {
    region: 'Татарстан',
    label: 'Актюбинский',
  },
  {
    region: 'Татарстан',
    label: 'Алексеевское',
  },
  {
    region: 'Татарстан',
    label: 'Альметьевск',
  },
  // {
  //   region: "Татарстан",
  //   label: "Альметьевск",
  // },
  {
    region: 'Татарстан',
    label: 'Апастово',
  },
  {
    region: 'Татарстан',
    label: 'Арск',
  },
  {
    region: 'Татарстан',
    label: 'Бавлы',
  },
  {
    region: 'Татарстан',
    label: 'Базарные Матаки',
  },
  {
    region: 'Татарстан',
    label: 'Балтаси',
  },
  {
    region: 'Татарстан',
    label: 'Богатые Сабы',
  },
  {
    region: 'Татарстан',
    label: 'Брежнев',
  },
  {
    region: 'Татарстан',
    label: 'Бугульма',
  },
  {
    region: 'Татарстан',
    label: 'Буинск',
  },
  {
    region: 'Татарстан',
    label: 'Васильево',
  },
  {
    region: 'Татарстан',
    label: 'Верхний Услон',
  },
  {
    region: 'Татарстан',
    label: 'Высокая Гора',
  },
  {
    region: 'Татарстан',
    label: 'Дербешкинский',
  },
  {
    region: 'Татарстан',
    label: 'Елабуга',
  },
  {
    region: 'Татарстан',
    label: 'Заинск',
  },
  {
    region: 'Татарстан',
    label: 'Зеленодольск',
  },
  {
    region: 'Татарстан',
    label: 'Казань',
  },
  {
    region: 'Татарстан',
    label: 'Камское Устье',
  },
  {
    region: 'Татарстан',
    label: 'Карабаш',
  },
  {
    region: 'Татарстан',
    label: 'Куйбышев',
  },
  {
    region: 'Татарстан',
    label: 'Кукмод',
  },
  {
    region: 'Татарстан',
    label: 'Кукмор',
  },
  {
    region: 'Татарстан',
    label: 'Лаишево',
  },
  {
    region: 'Татарстан',
    label: 'Лениногорск',
  },
  {
    region: 'Татарстан',
    label: 'Мамадыш',
  },
  {
    region: 'Татарстан',
    label: 'Менделеевск',
  },
  {
    region: 'Татарстан',
    label: 'Мензелинск',
  },
  {
    region: 'Татарстан',
    label: 'Муслюмово',
  },
  {
    region: 'Татарстан',
    label: 'Набережные Челны',
  },
  {
    region: 'Татарстан',
    label: 'Нижнекамск',
  },
  {
    region: 'Татарстан',
    label: 'Новошешминск',
  },
  {
    region: 'Татарстан',
    label: 'Нурлат',
  },
  {
    region: 'Татарстан',
    label: 'Пестрецы',
  },
  {
    region: 'Татарстан',
    label: 'Рыбная Слобода',
  },
  {
    region: 'Татарстан',
    label: 'Сарманово',
  },
  {
    region: 'Татарстан',
    label: 'Старое Дрожжаное',
  },
  {
    region: 'Татарстан',
    label: 'Тетюши',
  },
  {
    region: 'Татарстан',
    label: 'Чистополь',
  },
  {
    region: 'Тверская обл.',
    label: 'Андреаполь',
  },
  {
    region: 'Тверская обл.',
    label: 'Бежецк',
  },
  {
    region: 'Тверская обл.',
    label: 'Белый',
  },
  {
    region: 'Тверская обл.',
    label: 'Белый Городок',
  },
  {
    region: 'Тверская обл.',
    label: 'Березайка',
  },
  {
    region: 'Тверская обл.',
    label: 'Бологое',
  },
  {
    region: 'Тверская обл.',
    label: 'Васильевский Мох',
  },
  {
    region: 'Тверская обл.',
    label: 'Выползово',
  },
  {
    region: 'Тверская обл.',
    label: 'Вышний Волочек',
  },
  {
    region: 'Тверская обл.',
    label: 'Жарковский',
  },
  {
    region: 'Тверская обл.',
    label: 'Западная Двина',
  },
  {
    region: 'Тверская обл.',
    label: 'Заречье',
  },
  {
    region: 'Тверская обл.',
    label: 'Зубцов',
  },
  {
    region: 'Тверская обл.',
    label: 'Изоплит',
  },
  {
    region: 'Тверская обл.',
    label: 'Калашниково',
  },
  {
    region: 'Тверская обл.',
    label: 'Калинин',
  },
  {
    region: 'Тверская обл.',
    label: 'Калязин',
  },
  {
    region: 'Тверская обл.',
    label: 'Кашин',
  },
  {
    region: 'Тверская обл.',
    label: 'Кесова Гора',
  },
  {
    region: 'Тверская обл.',
    label: 'Кимры',
  },
  {
    region: 'Тверская обл.',
    label: 'Конаково',
  },
  {
    region: 'Тверская обл.',
    label: 'Красный Холм',
  },
  {
    region: 'Тверская обл.',
    label: 'Кувшиново',
  },
  {
    region: 'Тверская обл.',
    label: 'Лесное',
  },
  {
    region: 'Тверская обл.',
    label: 'Лихославль',
  },
  {
    region: 'Тверская обл.',
    label: 'Максатиха',
  },
  {
    region: 'Тверская обл.',
    label: 'Молоково',
  },
  {
    region: 'Тверская обл.',
    label: 'Нелидово',
  },
  {
    region: 'Тверская обл.',
    label: 'Оленино',
  },
  {
    region: 'Тверская обл.',
    label: 'Осташков',
  },
  {
    region: 'Тверская обл.',
    label: 'Пено',
  },
  {
    region: 'Тверская обл.',
    label: 'Рамешки',
  },
  {
    region: 'Тверская обл.',
    label: 'Ржев',
  },
  {
    region: 'Тверская обл.',
    label: 'Сандово',
  },
  {
    region: 'Тверская обл.',
    label: 'Селижарово',
  },
  {
    region: 'Тверская обл.',
    label: 'Сонково',
  },
  {
    region: 'Тверская обл.',
    label: 'Спирово',
  },
  {
    region: 'Тверская обл.',
    label: 'Старица',
  },
  {
    region: 'Тверская обл.',
    label: 'Тверь',
  },
  {
    region: 'Тверская обл.',
    label: 'Торжок',
  },
  {
    region: 'Тверская обл.',
    label: 'Торопец',
  },
  {
    region: 'Тверская обл.',
    label: 'Удомля',
  },
  {
    region: 'Тверская обл.',
    label: 'Фирово',
  },
  {
    region: 'Томская обл.',
    label: 'Александровское',
  },
  {
    region: 'Томская обл.',
    label: 'Асино',
  },
  {
    region: 'Томская обл.',
    label: 'Бакчар',
  },
  {
    region: 'Томская обл.',
    label: 'Батурино',
  },
  {
    region: 'Томская обл.',
    label: 'Белый Яр',
  },
  {
    region: 'Томская обл.',
    label: 'Зырянское',
  },
  {
    region: 'Томская обл.',
    label: 'Итатка',
  },
  {
    region: 'Томская обл.',
    label: 'Каргасок',
  },
  {
    region: 'Томская обл.',
    label: 'Катайга',
  },
  {
    region: 'Томская обл.',
    label: 'Кожевниково',
  },
  {
    region: 'Томская обл.',
    label: 'Колпашево',
  },
  {
    region: 'Томская обл.',
    label: 'Кривошеино',
  },
  {
    region: 'Томская обл.',
    label: 'Мельниково',
  },
  {
    region: 'Томская обл.',
    label: 'Молчаново',
  },
  {
    region: 'Томская обл.',
    label: 'Парабель',
  },
  {
    region: 'Томская обл.',
    label: 'Первомайское',
  },
  {
    region: 'Томская обл.',
    label: 'Подгорное',
  },
  {
    region: 'Томская обл.',
    label: 'Северск',
  },
  {
    region: 'Томская обл.',
    label: 'Стрежевой',
  },
  {
    region: 'Томская обл.',
    label: 'Томск',
  },
  {
    region: 'Томская обл.',
    label: 'Тымск',
  },
  {
    region: 'Тува (Тувинская Респ.)',
    label: 'Ак-Довурак',
  },
  {
    region: 'Тува (Тувинская Респ.)',
    label: 'Бай Хаак',
  },
  {
    region: 'Тува (Тувинская Респ.)',
    label: 'Кызыл',
  },
  {
    region: 'Тува (Тувинская Респ.)',
    label: 'Самагалтай',
  },
  {
    region: 'Тува (Тувинская Респ.)',
    label: 'Сарыг-Сеп',
  },
  {
    region: 'Тува (Тувинская Респ.)',
    label: 'Суть-Холь',
  },
  {
    region: 'Тува (Тувинская Респ.)',
    label: 'Тоора-Хем',
  },
  {
    region: 'Тува (Тувинская Респ.)',
    label: 'Туран',
  },
  {
    region: 'Тува (Тувинская Респ.)',
    label: 'Тээли',
  },
  {
    region: 'Тува (Тувинская Респ.)',
    label: 'Хову-Аксы',
  },
  {
    region: 'Тува (Тувинская Респ.)',
    label: 'Чадан',
  },
  {
    region: 'Тува (Тувинская Респ.)',
    label: 'Шагонар',
  },
  {
    region: 'Тува (Тувинская Респ.)',
    label: 'Эрзин',
  },
  {
    region: 'Тульская обл.',
    label: 'Агеево',
  },
  {
    region: 'Тульская обл.',
    label: 'Алексин',
  },
  {
    region: 'Тульская обл.',
    label: 'Арсеньево',
  },
  {
    region: 'Тульская обл.',
    label: 'Барсуки',
  },
  {
    region: 'Тульская обл.',
    label: 'Бегичевский',
  },
  {
    region: 'Тульская обл.',
    label: 'Белев',
  },
  {
    region: 'Тульская обл.',
    label: 'Богородицк',
  },
  {
    region: 'Тульская обл.',
    label: 'Болохово',
  },
  {
    region: 'Тульская обл.',
    label: 'Велегож',
  },
  {
    region: 'Тульская обл.',
    label: 'Венев',
  },
  {
    region: 'Тульская обл.',
    label: 'Волово',
  },
  {
    region: 'Тульская обл.',
    label: 'Горелки',
  },
  {
    region: 'Тульская обл.',
    label: 'Донской',
  },
  {
    region: 'Тульская обл.',
    label: 'Дубна',
  },
  {
    region: 'Тульская обл.',
    label: 'Епифань',
  },
  {
    region: 'Тульская обл.',
    label: 'Ефремов',
  },
  {
    region: 'Тульская обл.',
    label: 'Заокский',
  },
  {
    region: 'Тульская обл.',
    label: 'Казановка',
  },
  {
    region: 'Тульская обл.',
    label: 'Кимовск',
  },
  {
    region: 'Тульская обл.',
    label: 'Киреевск',
  },
  {
    region: 'Тульская обл.',
    label: 'Куркино',
  },
  {
    region: 'Тульская обл.',
    label: 'Ленинский',
  },
  {
    region: 'Тульская обл.',
    label: 'Новомосковск',
  },
  {
    region: 'Тульская обл.',
    label: 'Одоев',
  },
  {
    region: 'Тульская обл.',
    label: 'Плавск',
  },
  {
    region: 'Тульская обл.',
    label: 'Суворов',
  },
  {
    region: 'Тульская обл.',
    label: 'Тула',
  },
  {
    region: 'Тульская обл.',
    label: 'Узловая',
  },
  {
    region: 'Тульская обл.',
    label: 'Щекино',
  },
  {
    region: 'Тульская обл.',
    label: 'Ясногорск',
  },
  {
    region: 'Тюменская обл.',
    label: 'Абатский',
  },
  {
    region: 'Тюменская обл.',
    label: 'Аксарка',
  },
  {
    region: 'Тюменская обл.',
    label: 'Армизонское',
  },
  {
    region: 'Тюменская обл.',
    label: 'Аромашево',
  },
  {
    region: 'Тюменская обл.',
    label: 'Белоярский',
  },
  {
    region: 'Тюменская обл.',
    label: 'Бердюжье',
  },
  {
    region: 'Тюменская обл.',
    label: 'Большое Сорокино',
  },
  {
    region: 'Тюменская обл.',
    label: 'Вагай',
  },
  {
    region: 'Тюменская обл.',
    label: 'Викулово',
  },
  {
    region: 'Тюменская обл.',
    label: 'Винзили',
  },
  {
    region: 'Тюменская обл.',
    label: 'Голышманово',
  },
  {
    region: 'Тюменская обл.',
    label: 'Губкинский',
  },
  {
    region: 'Тюменская обл.',
    label: 'Заводопетровский',
  },
  {
    region: 'Тюменская обл.',
    label: 'Заводоуковск',
  },
  {
    region: 'Тюменская обл.',
    label: 'Излучинск',
  },
  {
    region: 'Тюменская обл.',
    label: 'Исетское',
  },
  {
    region: 'Тюменская обл.',
    label: 'Ишим',
  },
  {
    region: 'Тюменская обл.',
    label: 'Казанское',
  },
  {
    region: 'Тюменская обл.',
    label: 'Казым-Мыс',
  },
  {
    region: 'Тюменская обл.',
    label: 'Когалым',
  },
  {
    region: 'Тюменская обл.',
    label: 'Кондинское',
  },
  {
    region: 'Тюменская обл.',
    label: 'Красноселькуп',
  },
  {
    region: 'Тюменская обл.',
    label: 'Лабытнанги',
  },
  {
    region: 'Тюменская обл.',
    label: 'Ларьяк',
  },
  {
    region: 'Тюменская обл.',
    label: 'Мегион',
  },
  {
    region: 'Тюменская обл.',
    label: 'Мужи',
  },
  {
    region: 'Тюменская обл.',
    label: 'Муравленко',
  },
  {
    region: 'Тюменская обл.',
    label: 'Надым',
  },
  {
    region: 'Тюменская обл.',
    label: 'Находка',
  },
  {
    region: 'Тюменская обл.',
    label: 'Нефтеюганск',
  },
  {
    region: 'Тюменская обл.',
    label: 'Нижневартовск',
  },
  {
    region: 'Тюменская обл.',
    label: 'Нижняя Тавда',
  },
  {
    region: 'Тюменская обл.',
    label: 'Новый Уренгой',
  },
  {
    region: 'Тюменская обл.',
    label: 'Ноябрьск',
  },
  {
    region: 'Тюменская обл.',
    label: 'Нягань',
  },
  {
    region: 'Тюменская обл.',
    label: 'Октябрьское',
  },
  {
    region: 'Тюменская обл.',
    label: 'Омутинский',
  },
  {
    region: 'Тюменская обл.',
    label: 'Радужный',
  },
  {
    region: 'Тюменская обл.',
    label: 'Салехард',
  },
  {
    region: 'Тюменская обл.',
    label: 'Сладково',
  },
  {
    region: 'Тюменская обл.',
    label: 'Советский',
  },
  {
    region: 'Тюменская обл.',
    label: 'Сургут',
  },
  {
    region: 'Тюменская обл.',
    label: 'Тазовский',
  },
  {
    region: 'Тюменская обл.',
    label: 'Тарко-Сале',
  },
  {
    region: 'Тюменская обл.',
    label: 'Тобольск',
  },
  {
    region: 'Тюменская обл.',
    label: 'Тюмень',
  },
  {
    region: 'Тюменская обл.',
    label: 'Уват',
  },
  {
    region: 'Тюменская обл.',
    label: 'Унъюган',
  },
  {
    region: 'Тюменская обл.',
    label: 'Упорово',
  },
  {
    region: 'Тюменская обл.',
    label: 'Урай',
  },
  {
    region: 'Тюменская обл.',
    label: 'Ханты-Мансийск',
  },
  {
    region: 'Тюменская обл.',
    label: 'Юрибей',
  },
  {
    region: 'Тюменская обл.',
    label: 'Ялуторовск',
  },
  {
    region: 'Тюменская обл.',
    label: 'Яр-Сале',
  },
  {
    region: 'Тюменская обл.',
    label: 'Ярково',
  },
  {
    region: 'Удмуртия',
    label: 'Алнаши',
  },
  {
    region: 'Удмуртия',
    label: 'Балезино',
  },
  {
    region: 'Удмуртия',
    label: 'Вавож',
  },
  {
    region: 'Удмуртия',
    label: 'Воткинск',
  },
  {
    region: 'Удмуртия',
    label: 'Глазов',
  },
  {
    region: 'Удмуртия',
    label: 'Грахово',
  },
  {
    region: 'Удмуртия',
    label: 'Дебесы',
  },
  {
    region: 'Удмуртия',
    label: 'Завьялово',
  },
  {
    region: 'Удмуртия',
    label: 'Игра',
  },
  {
    region: 'Удмуртия',
    label: 'Ижевск',
  },
  {
    region: 'Удмуртия',
    label: 'Кама',
  },
  {
    region: 'Удмуртия',
    label: 'Камбарка',
  },
  {
    region: 'Удмуртия',
    label: 'Каракулино',
  },
  {
    region: 'Удмуртия',
    label: 'Кез',
  },
  {
    region: 'Удмуртия',
    label: 'Кизнер',
  },
  {
    region: 'Удмуртия',
    label: 'Киясово',
  },
  {
    region: 'Удмуртия',
    label: 'Красногорское',
  },
  {
    region: 'Удмуртия',
    label: 'Можга',
  },
  {
    region: 'Удмуртия',
    label: 'Сарапул',
  },
  {
    region: 'Удмуртия',
    label: 'Селты',
  },
  {
    region: 'Удмуртия',
    label: 'Сюмси',
  },
  {
    region: 'Удмуртия',
    label: 'Ува',
  },
  {
    region: 'Удмуртия',
    label: 'Устинов',
  },
  {
    region: 'Удмуртия',
    label: 'Шаркан',
  },
  {
    region: 'Удмуртия',
    label: 'Юкаменское',
  },
  {
    region: 'Удмуртия',
    label: 'Якшур-Бодья',
  },
  {
    region: 'Удмуртия',
    label: 'Яр',
  },
  {
    region: 'Ульяновская обл.',
    label: 'Базарный Сызган',
  },
  {
    region: 'Ульяновская обл.',
    label: 'Барыш',
  },
  {
    region: 'Ульяновская обл.',
    label: 'Большое Нагаткино',
  },
  {
    region: 'Ульяновская обл.',
    label: 'Вешкайма',
  },
  {
    region: 'Ульяновская обл.',
    label: 'Глотовка',
  },
  {
    region: 'Ульяновская обл.',
    label: 'Димитровград',
  },
  {
    region: 'Ульяновская обл.',
    label: 'Игнатовка',
  },
  {
    region: 'Ульяновская обл.',
    label: 'Измайлово',
  },
  {
    region: 'Ульяновская обл.',
    label: 'Инза',
  },
  {
    region: 'Ульяновская обл.',
    label: 'Ишеевка',
  },
  {
    region: 'Ульяновская обл.',
    label: 'Канадей',
  },
  {
    region: 'Ульяновская обл.',
    label: 'Карсун',
  },
  {
    region: 'Ульяновская обл.',
    label: 'Кузоватово',
  },
  {
    region: 'Ульяновская обл.',
    label: 'Майна',
  },
  {
    region: 'Ульяновская обл.',
    label: 'Новая Малыкла',
  },
  {
    region: 'Ульяновская обл.',
    label: 'Новоспасское',
  },
  {
    region: 'Ульяновская обл.',
    label: 'Павловка',
  },
  {
    region: 'Ульяновская обл.',
    label: 'Радищево',
  },
  {
    region: 'Ульяновская обл.',
    label: 'Сенгилей',
  },
  {
    region: 'Ульяновская обл.',
    label: 'Старая Кулатка',
  },
  {
    region: 'Ульяновская обл.',
    label: 'Старая Майна',
  },
  {
    region: 'Ульяновская обл.',
    label: 'Сурское',
  },
  {
    region: 'Ульяновская обл.',
    label: 'Тереньга',
  },
  {
    region: 'Ульяновская обл.',
    label: 'Ульяновск',
  },
  {
    region: 'Ульяновская обл.',
    label: 'Чердаклы',
  },
  {
    region: 'Уральская обл.',
    label: 'Аксай',
  },
  {
    region: 'Уральская обл.',
    label: 'Дарьинское',
  },
  {
    region: 'Уральская обл.',
    label: 'Деркул',
  },
  {
    region: 'Уральская обл.',
    label: 'Джамбейты',
  },
  {
    region: 'Уральская обл.',
    label: 'Джаныбек',
  },
  {
    region: 'Уральская обл.',
    label: 'Казталовка',
  },
  {
    region: 'Уральская обл.',
    label: 'Калмыково',
  },
  {
    region: 'Уральская обл.',
    label: 'Каратобе',
  },
  {
    region: 'Уральская обл.',
    label: 'Переметное',
  },
  {
    region: 'Уральская обл.',
    label: 'Сайхин',
  },
  {
    region: 'Уральская обл.',
    label: 'Уральск',
  },
  {
    region: 'Уральская обл.',
    label: 'Федоровка',
  },
  {
    region: 'Уральская обл.',
    label: 'Фурманово',
  },
  {
    region: 'Уральская обл.',
    label: 'Чапаев',
  },
  {
    region: 'Хабаровский край',
    label: 'Амурск',
  },
  {
    region: 'Хабаровский край',
    label: 'Аян',
  },
  {
    region: 'Хабаровский край',
    label: 'Березовый',
  },
  {
    region: 'Хабаровский край',
    label: 'Бикин',
  },
  {
    region: 'Хабаровский край',
    label: 'Бира',
  },
  {
    region: 'Хабаровский край',
    label: 'Биракан',
  },
  {
    region: 'Хабаровский край',
    label: 'Богородское',
  },
  {
    region: 'Хабаровский край',
    label: 'Болонь',
  },
  {
    region: 'Хабаровский край',
    label: 'Ванино',
  },
  {
    region: 'Хабаровский край',
    label: 'Волочаевка Вторая',
  },
  {
    region: 'Хабаровский край',
    label: 'Высокогорный',
  },
  {
    region: 'Хабаровский край',
    label: 'Вяземский',
  },
  {
    region: 'Хабаровский край',
    label: 'Горный',
  },
  {
    region: 'Хабаровский край',
    label: 'Гурское',
  },
  {
    region: 'Хабаровский край',
    label: 'Дормидонтовка',
  },
  {
    region: 'Хабаровский край',
    label: 'Заветы Ильича',
  },
  {
    region: 'Хабаровский край',
    label: 'Известковый',
  },
  {
    region: 'Хабаровский край',
    label: 'Иннокентьевка',
  },
  {
    region: 'Хабаровский край',
    label: 'Комсомольск-на-Амуре',
  },
  {
    region: 'Хабаровский край',
    label: 'Ленинское',
  },
  {
    region: 'Хабаровский край',
    label: 'Нелькан',
  },
  {
    region: 'Хабаровский край',
    label: 'Николаевск-на-Амуре',
  },
  {
    region: 'Хабаровский край',
    label: 'Облучье',
  },
  {
    region: 'Хабаровский край',
    label: 'Охотск',
  },
  {
    region: 'Хабаровский край',
    label: 'Переяславка',
  },
  {
    region: 'Хабаровский край',
    label: 'Смидович',
  },
  {
    region: 'Хабаровский край',
    label: 'Советская Гавань',
  },
  {
    region: 'Хабаровский край',
    label: 'Софийск',
  },
  {
    region: 'Хабаровский край',
    label: 'Троицкое',
  },
  {
    region: 'Хабаровский край',
    label: 'Тугур',
  },
  {
    region: 'Хабаровский край',
    label: 'Хабаровск',
  },
  {
    region: 'Хабаровский край',
    label: 'Чегдомын',
  },
  {
    region: 'Хабаровский край',
    label: 'Чумикан',
  },
  {
    region: 'Хакасия',
    label: 'Абакан',
  },
  {
    region: 'Хакасия',
    label: 'Саяногорск',
  },
  {
    region: 'Ханты-Мансийский АО',
    label: 'Аган',
  },
  {
    region: 'Ханты-Мансийский АО',
    label: 'Игрим',
  },
  {
    region: 'Ханты-Мансийский АО',
    label: 'Излучинск',
  },
  {
    region: 'Ханты-Мансийский АО',
    label: 'Лангепас',
  },
  {
    region: 'Ханты-Мансийский АО',
    label: 'Лянтор',
  },
  {
    region: 'Ханты-Мансийский АО',
    label: 'Мегион',
  },
  {
    region: 'Ханты-Мансийский АО',
    label: 'Нефтеюганск',
  },
  {
    region: 'Ханты-Мансийский АО',
    label: 'Нижневартовск',
  },
  {
    region: 'Ханты-Мансийский АО',
    label: 'Нягань',
  },
  {
    region: 'Ханты-Мансийский АО',
    label: 'Покачи',
  },
  {
    region: 'Ханты-Мансийский АО',
    label: 'Приобье',
  },
  {
    region: 'Ханты-Мансийский АО',
    label: 'Пыть-Ях',
  },
  {
    region: 'Ханты-Мансийский АО',
    label: 'Радужный',
  },
  {
    region: 'Ханты-Мансийский АО',
    label: 'Сургут',
  },
  {
    region: 'Ханты-Мансийский АО',
    label: 'Урай',
  },
  {
    region: 'Ханты-Мансийский АО',
    label: 'Ханты-Мансийск',
  },
  {
    region: 'Ханты-Мансийский АО',
    label: 'Югорск',
  },
  {
    region: 'Челябинская обл.',
    label: 'Агаповка',
  },
  {
    region: 'Челябинская обл.',
    label: 'Аргаяш',
  },
  {
    region: 'Челябинская обл.',
    label: 'Аша',
  },
  {
    region: 'Челябинская обл.',
    label: 'Бакал',
  },
  {
    region: 'Челябинская обл.',
    label: 'Бреды',
  },
  {
    region: 'Челябинская обл.',
    label: 'Варна',
  },
  {
    region: 'Челябинская обл.',
    label: 'Верхнеуральск',
  },
  {
    region: 'Челябинская обл.',
    label: 'Верхний Уфалей',
  },
  {
    region: 'Челябинская обл.',
    label: 'Еманжелинск',
  },
  {
    region: 'Челябинская обл.',
    label: 'Златоуст',
  },
  {
    region: 'Челябинская обл.',
    label: 'Карабаш',
  },
  {
    region: 'Челябинская обл.',
    label: 'Карталы',
  },
  {
    region: 'Челябинская обл.',
    label: 'Касли',
  },
  {
    region: 'Челябинская обл.',
    label: 'Катав-Ивановск',
  },
  {
    region: 'Челябинская обл.',
    label: 'Копейск',
  },
  {
    region: 'Челябинская обл.',
    label: 'Коркино',
  },
  {
    region: 'Челябинская обл.',
    label: 'Кунашак',
  },
  {
    region: 'Челябинская обл.',
    label: 'Куса',
  },
  {
    region: 'Челябинская обл.',
    label: 'Кыштым',
  },
  {
    region: 'Челябинская обл.',
    label: 'Магнитогорск',
  },
  {
    region: 'Челябинская обл.',
    label: 'Миасс',
  },
  {
    region: 'Челябинская обл.',
    label: 'Озерск',
  },
  {
    region: 'Челябинская обл.',
    label: 'Октябрьское',
  },
  {
    region: 'Челябинская обл.',
    label: 'Пласт',
  },
  {
    region: 'Челябинская обл.',
    label: 'Сатка',
  },
  {
    region: 'Челябинская обл.',
    label: 'Сим',
  },
  {
    region: 'Челябинская обл.',
    label: 'Снежинск',
  },
  {
    region: 'Челябинская обл.',
    label: 'Трехгорный',
  },
  {
    region: 'Челябинская обл.',
    label: 'Троицк',
  },
  {
    region: 'Челябинская обл.',
    label: 'Увельский',
  },
  {
    region: 'Челябинская обл.',
    label: 'Уйское',
  },
  {
    region: 'Челябинская обл.',
    label: 'Усть-Катав',
  },
  {
    region: 'Челябинская обл.',
    label: 'Фершампенуаз',
  },
  {
    region: 'Челябинская обл.',
    label: 'Чебаркуль',
  },
  {
    region: 'Челябинская обл.',
    label: 'Челябинск',
  },
  {
    region: 'Челябинская обл.',
    label: 'Чесма',
  },
  {
    region: 'Челябинская обл.',
    label: 'Южно-Уральск',
  },
  {
    region: 'Челябинская обл.',
    label: 'Юрюзань',
  },
  {
    region: 'Чечено-Ингушетия',
    label: 'Аргун',
  },
  {
    region: 'Чечено-Ингушетия',
    label: 'Грозный',
  },
  {
    region: 'Чечено-Ингушетия',
    label: 'Гудермез',
  },
  {
    region: 'Чечено-Ингушетия',
    label: 'Малгобек',
  },
  {
    region: 'Чечено-Ингушетия',
    label: 'Назрань',
  },
  {
    region: 'Чечено-Ингушетия',
    label: 'Наурская',
  },
  {
    region: 'Чечено-Ингушетия',
    label: 'Ножай-Юрт',
  },
  {
    region: 'Чечено-Ингушетия',
    label: 'Орджоникидзевская',
  },
  {
    region: 'Чечено-Ингушетия',
    label: 'Советское',
  },
  {
    region: 'Чечено-Ингушетия',
    label: 'Урус-Мартан',
  },
  {
    region: 'Чечено-Ингушетия',
    label: 'Шали',
  },
  {
    region: 'Читинская обл.',
    label: 'Агинское',
  },
  {
    region: 'Читинская обл.',
    label: 'Аксеново-Зиловское',
  },
  {
    region: 'Читинская обл.',
    label: 'Акша',
  },
  {
    region: 'Читинская обл.',
    label: 'Александровский Завод',
  },
  {
    region: 'Читинская обл.',
    label: 'Амазар',
  },
  {
    region: 'Читинская обл.',
    label: 'Арбагар',
  },
  {
    region: 'Читинская обл.',
    label: 'Атамановка',
  },
  {
    region: 'Читинская обл.',
    label: 'Балей',
  },
  {
    region: 'Читинская обл.',
    label: 'Борзя',
  },
  {
    region: 'Читинская обл.',
    label: 'Букачача',
  },
  {
    region: 'Читинская обл.',
    label: 'Газимурский Завод',
  },
  {
    region: 'Читинская обл.',
    label: 'Давенда',
  },
  {
    region: 'Читинская обл.',
    label: 'Дарасун',
  },
  {
    region: 'Читинская обл.',
    label: 'Дровяная',
  },
  {
    region: 'Читинская обл.',
    label: 'Дульдурга',
  },
  {
    region: 'Читинская обл.',
    label: 'Жиндо',
  },
  {
    region: 'Читинская обл.',
    label: 'Забайкальск',
  },
  {
    region: 'Читинская обл.',
    label: 'Итака',
  },
  {
    region: 'Читинская обл.',
    label: 'Калга',
  },
  {
    region: 'Читинская обл.',
    label: 'Карымское',
  },
  {
    region: 'Читинская обл.',
    label: 'Кличка',
  },
  {
    region: 'Читинская обл.',
    label: 'Ключевский',
  },
  {
    region: 'Читинская обл.',
    label: 'Кокуй',
  },
  {
    region: 'Читинская обл.',
    label: 'Краснокаменск',
  },
  {
    region: 'Читинская обл.',
    label: 'Красный Чикой',
  },
  {
    region: 'Читинская обл.',
    label: 'Кыра',
  },
  {
    region: 'Читинская обл.',
    label: 'Моготуй',
  },
  {
    region: 'Читинская обл.',
    label: 'Могоча',
  },
  {
    region: 'Читинская обл.',
    label: 'Нерчинск',
  },
  {
    region: 'Читинская обл.',
    label: 'Нерчинский Завод',
  },
  {
    region: 'Читинская обл.',
    label: 'Нижний Часучей',
  },
  {
    region: 'Читинская обл.',
    label: 'Оловянная',
  },
  {
    region: 'Читинская обл.',
    label: 'Первомайский',
  },
  {
    region: 'Читинская обл.',
    label: 'Петровск-Забайкальский',
  },
  {
    region: 'Читинская обл.',
    label: 'Приаргунск',
  },
  {
    region: 'Читинская обл.',
    label: 'Сретенск',
  },
  {
    region: 'Читинская обл.',
    label: 'Тупик',
  },
  {
    region: 'Читинская обл.',
    label: 'Улеты',
  },
  {
    region: 'Читинская обл.',
    label: 'Хилок',
  },
  {
    region: 'Читинская обл.',
    label: 'Чара',
  },
  {
    region: 'Читинская обл.',
    label: 'Чернышевск',
  },
  {
    region: 'Читинская обл.',
    label: 'Чита',
  },
  {
    region: 'Читинская обл.',
    label: 'Шелопугино',
  },
  {
    region: 'Читинская обл.',
    label: 'Шилка',
  },
  {
    region: 'Чувашия',
    label: 'Алатырь',
  },
  {
    region: 'Чувашия',
    label: 'Аликово',
  },
  {
    region: 'Чувашия',
    label: 'Батырева',
  },
  {
    region: 'Чувашия',
    label: 'Буинск',
  },
  {
    region: 'Чувашия',
    label: 'Вурнары',
  },
  {
    region: 'Чувашия',
    label: 'Ибреси',
  },
  {
    region: 'Чувашия',
    label: 'Канаш',
  },
  {
    region: 'Чувашия',
    label: 'Киря',
  },
  {
    region: 'Чувашия',
    label: 'Комсомольское',
  },
  {
    region: 'Чувашия',
    label: 'Красноармейское',
  },
  {
    region: 'Чувашия',
    label: 'Красные Четаи',
  },
  {
    region: 'Чувашия',
    label: 'Кугеси',
  },
  {
    region: 'Чувашия',
    label: 'Мариинский Посад',
  },
  {
    region: 'Чувашия',
    label: 'Моргауши',
  },
  {
    region: 'Чувашия',
    label: 'Новочебоксарск',
  },
  {
    region: 'Чувашия',
    label: 'Порецкое',
  },
  {
    region: 'Чувашия',
    label: 'Урмары',
  },
  {
    region: 'Чувашия',
    label: 'Цивильск',
  },
  {
    region: 'Чувашия',
    label: 'Чебоксары',
  },
  {
    region: 'Чувашия',
    label: 'Шемурша',
  },
  {
    region: 'Чувашия',
    label: 'Шумерля',
  },
  {
    region: 'Чувашия',
    label: 'Ядрин',
  },
  {
    region: 'Чувашия',
    label: 'Яльчики',
  },
  {
    region: 'Чувашия',
    label: 'Янтиково',
  },
  {
    region: 'Чукотский АО',
    label: 'Анадырь',
  },
  {
    region: 'Чукотский АО',
    label: 'Билибино',
  },
  {
    region: 'Ямало-Ненецкий АО',
    label: 'Губкинский',
  },
  {
    region: 'Ямало-Ненецкий АО',
    label: 'Заполярный',
  },
  {
    region: 'Ямало-Ненецкий АО',
    label: 'Муравленко',
  },
  {
    region: 'Ямало-Ненецкий АО',
    label: 'Надым',
  },
  {
    region: 'Ямало-Ненецкий АО',
    label: 'Новый Уренгой',
  },
  {
    region: 'Ямало-Ненецкий АО',
    label: 'Ноябрьск',
  },
  {
    region: 'Ямало-Ненецкий АО',
    label: 'Пуровск',
  },
  {
    region: 'Ямало-Ненецкий АО',
    label: 'Салехард',
  },
  {
    region: 'Ямало-Ненецкий АО',
    label: 'Тарко',
  },
  {
    region: 'Ярославская обл.',
    label: 'Андропов',
  },
  {
    region: 'Ярославская обл.',
    label: 'Берендеево',
  },
  {
    region: 'Ярославская обл.',
    label: 'Большое Село',
  },
  {
    region: 'Ярославская обл.',
    label: 'Борисоглебский',
  },
  {
    region: 'Ярославская обл.',
    label: 'Брейтово',
  },
  {
    region: 'Ярославская обл.',
    label: 'Бурмакино',
  },
  {
    region: 'Ярославская обл.',
    label: 'Варегово',
  },
  {
    region: 'Ярославская обл.',
    label: 'Волга',
  },
  {
    region: 'Ярославская обл.',
    label: 'Гаврилов Ям',
  },
  {
    region: 'Ярославская обл.',
    label: 'Данилов',
  },
  {
    region: 'Ярославская обл.',
    label: 'Любим',
  },
  {
    region: 'Ярославская обл.',
    label: 'Мышкино',
  },
  {
    region: 'Ярославская обл.',
    label: 'Некрасовское',
  },
  {
    region: 'Ярославская обл.',
    label: 'Новый Некоуз',
  },
  {
    region: 'Ярославская обл.',
    label: 'Переславль-Залесский',
  },
  {
    region: 'Ярославская обл.',
    label: 'Пошехонье-Володарск',
  },
  {
    region: 'Ярославская обл.',
    label: 'Ростов',
  },
  {
    region: 'Ярославская обл.',
    label: 'Рыбинск',
  },
  {
    region: 'Ярославская обл.',
    label: 'Тутаев',
  },
  {
    region: 'Ярославская обл.',
    label: 'Углич',
  },
  {
    region: 'Ярославская обл.',
    label: 'Ярославль',
  },
];

const cities = citiesList.map((city, index) => ({ ...city, id: index }));

export default cities;
